import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { IDefaultResponse } from "@skm-shared/api";
import { IUser, USER_ROUTES } from "@skm-shared/user";

import { MONITOR_TREE_QUERY_KEYS } from "@entities/monitor/api/tree.service";
import { apiFetch, auth } from "@shared/api/api-fetch";
import { queryClient } from "@shared/api/queryClient";

export const useGetUsers = () =>
  useQuery({
    queryKey: ["users"],
    queryFn: () =>
      apiFetch<IDefaultResponse<IUser[]>>(USER_ROUTES.users(), {
        method: "GET"
      }),
    select: ({ data }) => ({
      list: data.data,
      hashMap: (by: "id" | "email") => new Map(data.data.map(user => [user[by], user]))
    })
  });

export const useGetUser = () =>
  useQuery<IUser | undefined>({
    queryKey: ["user"],
    queryFn: async () => {
      try {
        return await auth.fetchUser();
      } catch (e) {
        auth.login();
      }
    }
  });

export const useLogoutUser = () =>
  useMutation({
    mutationKey: ["logout-user"],
    mutationFn: () => auth.logout(),
    onSuccess() {
      window.location.reload();
    }
  });

export const useAddUser = () =>
  useMutation({
    mutationKey: ["add-user"],
    mutationFn: (dto: Pick<IUser, "email"> & { roleIds: string[] }) =>
      apiFetch<IDefaultResponse>(USER_ROUTES.addUser(), { method: "POST", data: dto }),
    onSuccess: ({ data }) => {
      void queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success(data.message);
    }
  });

export const useUpdateUser = () =>
  useMutation({
    mutationKey: ["update-user"],
    mutationFn: (dto: Omit<IUser, "roles"> & { roleIds: string[] }) =>
      apiFetch<IDefaultResponse>(USER_ROUTES.updateUser(), { method: "PATCH", data: dto }),
    onSuccess: ({ data }) => {
      void queryClient.invalidateQueries({ queryKey: ["users"] });
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.tree] });
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.treeNodes] });
      toast.success(data.message);
    }
  });

export const useRemoveUser = () =>
  useMutation({
    mutationKey: ["remove-user"],
    mutationFn: (id: string) => apiFetch<IDefaultResponse>(USER_ROUTES.removeUser(id), { method: "DELETE" }),
    onSuccess: ({ data }) => {
      void queryClient.invalidateQueries({ queryKey: ["users"] });
      toast.success(data.message);
    }
  });
