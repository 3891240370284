import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { IDefaultResponse } from "@skm-shared/api";
import { DASHBOARD_COMMENTS_ROUTES, IComment } from "@skm-shared/dashboard-comments";

import { apiFetch } from "@shared/api/api-fetch";
import { queryClient } from "@shared/api/queryClient";

export const TREE_QUERY_KEYS = {
  comments: "comments",
  addComment: "add-comments",
  updateComment: "update-comment",
  removeComment: "remove-comment"
};

export const useGetComments = (nodeId: string) =>
  useQuery({
    queryKey: [TREE_QUERY_KEYS.comments, nodeId],
    queryFn: () =>
      apiFetch<IDefaultResponse<IComment[]>>(DASHBOARD_COMMENTS_ROUTES.getById(), {
        method: "GET",
        params: { nodeId }
      }),
    select: ({ data }) => data
  });

export const useAddComment = () =>
  useMutation({
    mutationKey: [TREE_QUERY_KEYS.addComment],
    mutationFn: (dto: Omit<IComment, "id">) =>
      apiFetch<IDefaultResponse<IComment>>(DASHBOARD_COMMENTS_ROUTES.add(), { method: "POST", data: dto }),
    onSuccess({ data }) {
      void queryClient.invalidateQueries({ queryKey: ["comments"] });
      toast.success(data.message);
    }
  });

export const useEditComment = () =>
  useMutation({
    mutationKey: [TREE_QUERY_KEYS.updateComment],
    mutationFn: (dto: IComment) =>
      apiFetch<IDefaultResponse>(DASHBOARD_COMMENTS_ROUTES.update(), {
        method: "PATCH",
        data: dto
      }),
    onSuccess({ data }) {
      void queryClient.invalidateQueries({ queryKey: ["comments"] });
      toast.success(data.message);
    }
  });

export const useRemoveComment = () =>
  useMutation({
    mutationKey: [TREE_QUERY_KEYS.removeComment],
    mutationFn: (commentId: string) =>
      apiFetch<IDefaultResponse>(DASHBOARD_COMMENTS_ROUTES.remove(commentId), { method: "DELETE" }),
    onSuccess({ data }) {
      void queryClient.invalidateQueries({ queryKey: ["comments"] });
      toast.success(data.message);
    }
  });
