import { PaddedContentContainer } from "@brusnika.tech/ui-portal";

import UpdateMonitor from "@widgets/update-monitor/ui/UpdateMonitor";

const UpdateMonitorPage = () => {
  return (
    <PaddedContentContainer style={{ backgroundColor: "#fff" }}>
      <UpdateMonitor />
    </PaddedContentContainer>
  );
};

export default UpdateMonitorPage;
