import { RouteObject } from "react-router-dom";

import { ICameraRoute } from "@skm-shared/cameras/cameras";

import IFrameCameraPage from "@pages/IFrameCamera.page";

export const getCameraRoutes = (tree?: ICameraRoute) => {
  if (!tree) return [];
  const routes: RouteObject[] = [];

  const routeMapper = (route: ICameraRoute): RouteObject => ({
    id: route.id,
    path: route.path,
    element: <IFrameCameraPage route={route} />
  });

  const routeHandler = (route: ICameraRoute) => {
    if (!route.children.length) {
      routes.push(routeMapper(route));
      return;
    }

    route.children.forEach(routeHandler);
  };

  tree.children.forEach(routeHandler);

  return routes;
};
