import { FC } from "react";

import { IconClose, IconCheck } from "@brusnika.tech/ui-icons";
import { Button } from "@brusnika.tech/ui-kit";
import { Stack, TextField, Typography } from "@mui/material";
import { useForm } from "react-hook-form";

import { IComment } from "@skm-shared/dashboard-comments";

import { grayColor } from "@shared/lib/utils/text-colors";

interface Props {
  onSubmit: (form: IComment) => void;
  comment: IComment;
  handleEdit: (commentId: null) => void;
}

const PageCommentUpdateForm: FC<Props> = ({ onSubmit, comment, handleEdit }) => {
  const { register, handleSubmit } = useForm<IComment>({ values: comment });

  return (
    <Stack paddingY={1}>
      <Typography color={grayColor} typography="fontListItem2">
        Редактирование комментария
      </Typography>

      <Stack direction="row" justifyContent="space-between">
        <TextField {...register("comment")} placeholder="Комментарий" />

        <Stack direction="row">
          <Button startIcon={<IconCheck />} sx={{ minWidth: 20 }} onClick={handleSubmit(onSubmit)} />
          <Button startIcon={<IconClose />} sx={{ minWidth: 20 }} onClick={() => handleEdit(null)} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PageCommentUpdateForm;
