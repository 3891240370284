import { PaddedContentContainer } from "@brusnika.tech/ui-portal";

import UserVisitsTable from "@widgets/user-visits/ui/UserVisitsTable";

const UserVisitPage = () => {
  return (
    <PaddedContentContainer style={{ backgroundColor: "#fff" }}>
      <UserVisitsTable />
    </PaddedContentContainer>
  );
};

export default UserVisitPage;
