import { APIRoutes, IDefaultResponse } from "../api";

import { IRole, IUser } from "./user";

export interface UserApi {
  me(...omitted: any[]): Promise<IDefaultResponse<IUser | undefined>>;
  users(...omitted: any[]): Promise<IDefaultResponse<IUser[]>>;
  getRoles(...omitted: any[]): Promise<IDefaultResponse<IRole[]>>;
  addUser(...omitted: any[]): Promise<IDefaultResponse<IUser>>;
  addRole(...omitted: any[]): Promise<IDefaultResponse<IRole>>;
  addRoleToUsers(...omitted: any[]): Promise<IDefaultResponse>;
  updateRole(...omitted: any[]): Promise<IDefaultResponse>;
  updateUser(...omitted: any[]): Promise<IDefaultResponse>;
  removeRole(...omitted: any[]): Promise<IDefaultResponse>;
  removeUserRole(...omitted: any[]): Promise<IDefaultResponse>;
  removeUser(...omitted: any[]): Promise<IDefaultResponse>;
}

export const PREFIX = "users";

export const USER_ROUTES: APIRoutes<UserApi> = {
  me: () => `${PREFIX}/me`,
  users: () => `${PREFIX}/all`,
  getRoles: () => `${PREFIX}/role`,
  addUser: () => `${PREFIX}/add`,
  addRole: () => `${PREFIX}/role/add`,
  addRoleToUsers: () => `${PREFIX}/users-role`,
  updateUser: () => `${PREFIX}/user`,
  updateRole: () => `${PREFIX}/role`,
  removeRole: (id: string) => `${PREFIX}/role/${id}`,
  removeUserRole: (id: string) => `${PREFIX}/user-role/${id}`,
  removeUser: (id: string) => `${PREFIX}/user/${id}`
};
