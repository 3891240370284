import { FC, useEffect, useState } from "react";

import { Stack, Typography } from "@mui/material";
import { useShallow } from "zustand/react/shallow";

import { IComment } from "@skm-shared/dashboard-comments";

import {
  useAddComment,
  useEditComment,
  useGetComments,
  useRemoveComment
} from "@entities/page-comments/api/comment.service";
import { useCommentStore } from "@entities/page-comments/model/comment.store";
import { useUserStore } from "@entities/user/model/user.store";
import { ICreateCommentForm } from "@features/page-comment/types";
import PageCommentCreateForm from "@features/page-comment/ui/PageCommentCreateForm";
import PageCommentItem from "@features/page-comment/ui/PageCommentItem";
import PageCommentUpdateForm from "@features/page-comment/ui/PageCommentUpdateForm";
import { grayColor } from "@shared/lib/utils/text-colors";

interface Props {
  nodeId: string;
}

const PageComments: FC<Props> = ({ nodeId }) => {
  const { data } = useGetComments(nodeId);
  const { mutateAsync: addMutate } = useAddComment();
  const { mutateAsync: editMutate } = useEditComment();
  const { mutateAsync: removeMutate } = useRemoveComment();

  const user = useUserStore(state => state.user);
  const { comments, addComment, removeComment, setComments } = useCommentStore(useShallow(state => state));

  const [editingComment, setEditingComment] = useState<string | null>(null);

  const handleEdit = (commentId: string | null) => {
    setEditingComment(commentId);
  };

  const onSubmit = async (form: ICreateCommentForm) => {
    try {
      const { data } = await addMutate({
        comment: form.comment,
        skmTreeNodeId: nodeId,
        userId: user.id,
        createdAt: new Date()
      });

      addComment(data.data);
    } catch (e) {
      console.error(e);
    }
  };

  const onEditSubmit = async (form: IComment) => {
    try {
      await editMutate(form);
      handleEdit(null);
    } catch (e) {
      console.error(e);
    }
  };

  const onRemove = async (commentId: string) => {
    try {
      await removeMutate(commentId);
      removeComment(commentId);
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (data?.data) {
      setComments(data.data);
    }
  }, [data?.data, setComments]);

  return (
    <Stack gap={3} p={5}>
      <PageCommentCreateForm onSubmit={onSubmit} />

      <div>
        {!comments.length && (
          <Stack paddingY={5}>
            <Typography color={grayColor} textAlign="center">
              нет комментариев
            </Typography>
          </Stack>
        )}

        {!!comments.length &&
          comments.map(item =>
            item.id === editingComment ? (
              <PageCommentUpdateForm comment={item} handleEdit={handleEdit} key={item.id} onSubmit={onEditSubmit} />
            ) : (
              <PageCommentItem
                comment={item}
                email={user.email}
                handleEdit={handleEdit}
                key={item.id}
                onRemove={onRemove}
              />
            )
          )}
      </div>
    </Stack>
  );
};

export default PageComments;
