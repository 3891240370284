import { TreeItem } from "@widgets/sidebars/types";

export const createItem = ({
  id,
  text,
  hasDivider,
  parentId
}: {
  id: string;
  text: string;
  hasDivider: boolean;
  parentId?: string;
}): TreeItem => ({
  id,
  parentId,
  title: text,
  icon: <div />,
  hasDivider,
  children: []
});
