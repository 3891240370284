import { FC, ReactElement, useEffect, useState } from "react";

import { InlineEditable, MultiSelect } from "@brusnika.tech/ui-kit";
import { TextField } from "@mui/material";
import { CellContext } from "@tanstack/react-table";

import { isOptionEqualToValue } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";
import { ColumnMeta } from "@shared/ui/table/types";

interface Props {
  cell: CellContext<any, any>;
  format?: (v: never, rowIndex: number) => string | ReactElement | Record<string, any>;
  meta?: ColumnMeta;
}

const EditableCell: FC<Props> = ({ cell, meta }) => {
  const {
    getValue,
    row: { index },
    column: { id },
    table
  } = cell;
  const initialValue = getValue<unknown>();
  const formattedValue: unknown = meta?.formatValue ? meta.formatValue(getValue<never>(), index) : getValue<unknown>();
  const [value, setValue] = useState(formattedValue);

  const onBlur = () => {
    table.options.meta?.updateData(index, id, value);
  };

  useEffect(() => {
    setValue(formattedValue);
  }, [initialValue]);

  if (Array.isArray(initialValue)) {
    return (
      <InlineEditable variant="clear">
        <MultiSelect
          isOptionEqualToValue={isOptionEqualToValue}
          options={(meta?.options as ISelectElement[]) ?? []}
          value={value as ISelectElement[]}
          onBlur={onBlur}
          onChange={(_, v) => setValue(v)}
        />
      </InlineEditable>
    );
  }

  return (
    <InlineEditable variant="clear">
      <TextField value={value as string} onBlur={onBlur} onChange={e => setValue(e.target.value)} />
    </InlineEditable>
  );
};

export default EditableCell;
