import { PaddedContentContainer } from "@brusnika.tech/ui-portal";

import ManageMonitors from "@widgets/manage-monitors/ui/ManageMonitors";

const AdminTreePage = () => {
  return (
    <PaddedContentContainer style={{ backgroundColor: "#fff" }}>
      <ManageMonitors />
    </PaddedContentContainer>
  );
};

export default AdminTreePage;
