import { create } from "zustand";

import { IComment } from "@skm-shared/dashboard-comments";

type State = {
  comments: IComment[];
};

type Actions = {
  getComments: () => IComment[];
  addComment: (comment: IComment) => void;
  setComments: (comments: IComment[]) => void;
  removeComment: (commentId: string) => void;
};

export const useCommentStore = create<State & Actions>((set, get) => ({
  comments: [],
  removeComment: commentId =>
    set(state => {
      const fii = state.comments.filter(c => c.id !== commentId);
      return { comments: fii };
    }),
  addComment: comment =>
    set(state => {
      state.comments.push(comment);
      return state;
    }),
  setComments: comments => set(() => ({ comments })),
  getComments: () => get().comments
}));
