import { PropsWithChildren, useEffect, useState } from "react";

import { ConfigState } from "@brusnika.tech/ui-config-provider";
import { Spinner } from "@brusnika.tech/ui-kit";
import { Stack } from "@mui/material";
import { DevTools, FormatSimple, Tolgee, TolgeeInstance, TolgeeProvider } from "@tolgee/react";

import { ConfigType } from "@app/config.schema";

interface Props extends PropsWithChildren {
  useConfig: () => ConfigState<ConfigType>;
}
export const BrusnikaTolgeeProvider = ({ children, useConfig }: Props) => {
  const { isLoaded, config } = useConfig();
  const [tolgee, setTolgee] = useState<TolgeeInstance | null>(null);

  useEffect(() => {
    if (isLoaded && config) {
      const tolgeeInstance = Tolgee()
        .use(DevTools())
        .use(FormatSimple())
        .init({
          language: config.language,
          availableLanguages: ["ru", "en"],
          ns: ["common"],
          tagNewKeys: ["draft"],
          apiUrl: config.tolgeeApiUrl,
          apiKey: config.tolgeeKey
        });

      setTolgee(tolgeeInstance);
    }
  }, [isLoaded, config]);

  if (!tolgee)
    return (
      <Stack alignItems="center" height="95vh" justifyContent="center" width="95vw">
        <Spinner size="large" text="Инициализация перевода" />
      </Stack>
    );

  return (
    <TolgeeProvider fallback="Loading..." tolgee={tolgee}>
      {children}
    </TolgeeProvider>
  );
};
