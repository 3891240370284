import { FC, Fragment } from "react";

import { IconEdit, IconTrash } from "@brusnika.tech/ui-icons";
import { Button } from "@brusnika.tech/ui-kit";
import { Grid, Tooltip, Typography } from "@mui/material";

import { ILastUpdate } from "@skm-shared/dashboard-last-update";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { formatDate } from "@shared/lib/utils/formatDate";

interface Props {
  item: ILastUpdate;
  onRemove: (id: string) => void;
  onSetEdit: (id: string | null) => void;
}

const UpdateMonitorItem: FC<Props> = ({ item, onRemove, onSetEdit }) => {
  const { data: nodeData } = useGetMonitorNodes();

  const pageTitle = nodeData?.hashMap("id").get(item.skmTreeNodeId ?? "")?.title;
  const pageTitlePlaceholder = "Страница не привязана";
  const date = formatDate(item.datetime, "DD.MM.YYYY HH:mm");
  const delay = 700;

  return (
    <Fragment>
      <Grid item zeroMinWidth sm={4}>
        <Tooltip enterDelay={delay} enterNextDelay={delay} title={item.view}>
          <Typography noWrap fontSize={12}>
            {item.view}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item sm={3}>
        <Tooltip enterDelay={delay} enterNextDelay={delay} title={pageTitle || pageTitlePlaceholder}>
          <Typography fontSize={12}>{pageTitle || pageTitlePlaceholder}</Typography>
        </Tooltip>
      </Grid>

      <Grid item zeroMinWidth sm={2}>
        <Tooltip enterDelay={delay} enterNextDelay={delay} title={date}>
          <Typography noWrap fontSize={12}>
            {date}
          </Typography>
        </Tooltip>
      </Grid>

      <Grid item sm={3}>
        <Button startIcon={<IconEdit />} sx={{ minWidth: 20 }} onClick={() => onSetEdit(item.id)} />
        <Button startIcon={<IconTrash />} sx={{ minWidth: 20 }} onClick={() => onRemove(item.id)} />
      </Grid>
    </Fragment>
  );
};

export default UpdateMonitorItem;
