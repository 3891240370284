import { PaddedContentContainer } from "@brusnika.tech/ui-portal";
import { Divider, Stack } from "@mui/material";
import { useTolgee } from "@tolgee/react";

import RoleActions from "@widgets/role/ui/RoleActions";
import RoleTable from "@widgets/role/ui/RoleTable";

const RolesPage = () => {
  const tolgee = useTolgee(["language"]);

  return (
    <PaddedContentContainer style={{ backgroundColor: "#fff" }}>
      <Stack gap={2}>
        <RoleActions />
        <Divider />
        <RoleTable />
        <select value={tolgee.getLanguage()} onChange={e => void tolgee.changeLanguage(e.target.value)}>
          <option value="en">🇬🇧 English</option>
          <option value="ru">🇷🇺 Русский</option>
        </select>
      </Stack>
    </PaddedContentContainer>
  );
};

export default RolesPage;
