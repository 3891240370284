import { FC } from "react";

interface Props {
  title: string;
  src: string;
}

const IFrame: FC<Props> = ({ src, title }) => {
  const clearLocalStorage = () => {
    const context = localStorage.getItem("dashboard__explore_context");
    if (!context) return;
    const parsedContext = JSON.parse(context) as Record<string, unknown>;
    if (Object.keys(parsedContext).length > 50) {
      localStorage.setItem("dashboard__explore_context", "{}");
    }
  };

  return (
    <iframe
      src={src}
      style={{ border: "none", width: "100%", height: "100%", backgroundColor: "#fff" }}
      title={title}
      onLoad={clearLocalStorage}
    />
  );
};

export default IFrame;
