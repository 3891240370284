import { create } from "zustand";

type State = {
  isFullscreen: boolean;
};

type Actions = {
  setFullscreen: (v: boolean) => void;
};

export const useRootStore = create<State & Actions>(set => ({
  isFullscreen: false,
  setFullscreen: v => set(state => ({ ...state, isFullscreen: v }))
}));
