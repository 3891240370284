import dayjs, { extend } from "dayjs";
import customPlugin from "dayjs/plugin/customParseFormat.js";

extend(customPlugin);

export const checkDate: (val: string) => Date | undefined = val => {
  const dateFormat: string = "DD.MM.YYYY HH:mm";

  if (dayjs(val, dateFormat, true).isValid()) {
    const [date, time]: string[] = val.split(" ");
    const [d, m, y]: string[] = date.split(".");
    return new Date(`${y}-${m}-${d} ${time}`);
  }

  return undefined;
};
