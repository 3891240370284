export type AppConfig = {
  SUPERSET_URL: string;
  POWER_BI_URL: string;
  MODE: Environment;
  CAMERAS_ENABLED: boolean;
  ERP_ENABLED: boolean;
  DOCUMENT_ENABLED: boolean;
};

export enum Environment {
  DEV = "dev",
  PROD = "prod"
}

export type Algorithm =
  | "HS256"
  | "HS384"
  | "HS512"
  | "RS256"
  | "RS384"
  | "RS512"
  | "ES256"
  | "ES384"
  | "ES512"
  | "PS256"
  | "PS384"
  | "PS512"
  | "none";
