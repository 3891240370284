import { TreeItem } from "@widgets/sidebars/types";

export const createFolder = ({
  id,
  text,
  children,
  hasDivider = false,
  isCollapsible = false
}: {
  id: string;
  text: string;
  children: TreeItem[];
  hasDivider?: boolean;
  isCollapsible?: boolean;
}): TreeItem => ({
  id,
  title: text,
  icon: <div />,
  children: children,
  hasDivider,
  isCollapsible
});
