import { BaseListItem } from "@shared/ui/tree/types";

export type TreeItem = BaseListItem;

export type NavigateTo = "path" | "id";

export enum SidebarNames {
  MAIN = "sidebar-tree-client",
  ADMIN_TREE = "sidebar-tree-admin",
  ADMIN_MAIN = "sidebar-admin-main",
  CAMERAS = "sidebar-cameras"
}
