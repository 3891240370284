import { IRoute, TreeContentStatus } from "packages/shared/src/monitors";

import { useConfigStore } from "@entities/config/model/config.store";
import { useVisitPage } from "@entities/user/lib/useVisitPage";
import IFrame from "@features/monitor-frame/ui/IFrame";
import MaintenancePlaceholder from "@shared/ui/service-placeholders/MaintenancePlaceholder";

interface Props {
  route: IRoute;
}

const IFramePage = ({ route }: Props) => {
  useVisitPage(route);
  const config = useConfigStore(state => state.config);
  const url = `${route.frame?.host ?? config.SUPERSET_URL}${route.frame?.externalSrc || ""}`;

  if (route.contentStatus === TreeContentStatus.DEVELOP) {
    return <MaintenancePlaceholder description={""} subTitle={""} title={"Монитор в разработке"} />;
  }

  if (route.contentStatus === TreeContentStatus.MAINTENANCE) {
    return <MaintenancePlaceholder description={route.maintenanceDesc} />;
  }

  return <IFrame src={url} title={route.title} />;
};

export default IFramePage;
