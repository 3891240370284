import { create } from "zustand";

import { IUser } from "@skm-shared/user";

type State = {
  user: IUser;
};

type Actions = {
  setUser: (user: IUser) => void;
  getUser: () => IUser | undefined;
};

export const useUserStore = create<State & Actions>((set, get) => ({
  user: {} as IUser,
  setUser: user => set(() => ({ user })),
  getUser: () => {
    const { id, email, roles, createdAt, updatedAt } = get().user;

    if (email && id && createdAt) {
      return { email, id, roles, createdAt, updatedAt };
    }
  }
}));
