export const SidebarType = {
  MAIN: "main",
  ADMIN_MONITOR_TREE: "admin-monitor-tree",
  ADMIN_CAMERA_TREE: "admin-camera-tree",
  ADMIN_MAIN: "admin-main",
  CAMERAS: "cameras"
} as const;

export const TopRoutes = {
  ADMIN_CAMERA_TREE: "admin-tree-camera",
  ADMIN_MAIN: "admin",
  ADMIN_MONITOR_TREE: "admin-tree-monitors",
  CAMERAS: "cameras",
  MAIN: "main"
} as const;

export const AdminRoutes = {
  ROLES: "roles",
  USERS: "users",
  UPDATE_MONITOR: "update-monitor",
  VISITS: "visits"
};

export const QueryParams = {
  nodeId: "nodeId",
  onlyFrame: "o_f"
} as const;

export const YANDEX_COUNTER = 96578554;
