import { FC } from "react";

import { IconClose } from "@brusnika.tech/ui-icons";
import { SideSheet, useLayout } from "@brusnika.tech/ui-portal";
import { Stack, Typography } from "@mui/material";

import { INode } from "@skm-shared/monitors";

import PageComments from "@widgets/page-comments/ui/PageComments";

interface Props {
  node?: INode;
}

const RightSideBarContent: FC<Props> = ({ node }) => {
  const { toggleRightDrawer } = useLayout();

  if (!node) return null;

  return (
    <SideSheet
      overlap
      header={
        <Stack direction="row" justifyContent="space-between">
          <Typography variant="fontH2">Комментарии</Typography>
          <IconClose cursor="pointer" iconSize="medium" onClick={toggleRightDrawer} />
        </Stack>
      }
    >
      <PageComments nodeId={node.id} />
    </SideSheet>
  );
};

export default RightSideBarContent;
