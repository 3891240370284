import { IconTree } from "@brusnika.tech/ui-icons";
import { useLayout } from "@brusnika.tech/ui-portal";
import { Stack, Typography } from "@mui/material";

import { useGetCameraNodes } from "@entities/camera/api/camera.service";

interface Props {
  nodeId: string;
}

const CameraHeader = ({ nodeId }: Props) => {
  const { data: nodeData } = useGetCameraNodes();
  const { toggleLeftDrawer, isDesktop } = useLayout();

  const node = nodeData?.mapById.get(nodeId);
  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
      <Stack alignItems="center" direction="row" gap={1}>
        {!isDesktop && <IconTree iconSize="medium" onClick={toggleLeftDrawer} />}

        <Typography fontSize={isDesktop ? 18 : 16} fontWeight="bold">
          {node?.fullTitle || node?.title}
        </Typography>
      </Stack>
    </Stack>
  );
};

export default CameraHeader;
