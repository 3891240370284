import { FC } from "react";

import { Stack, Typography } from "@mui/material";

import styles from "./index.module.scss";

interface Props {
  title?: string;
  subTitle?: string;
  description?: string;
}

const MaintenancePlaceholder: FC<Props> = ({ title, subTitle, description }) => {
  const defaultTitle = "Монитор недоступен";
  const defaultSubTitle =
    "Мы проводим профилактические работы - обновляем сервис, чтобы он работал быстрее и лучше. Извините за неудобства";
  const defaultDescription = "Сервис начнет работать примерно в 16:00 по ЕКБ";

  return (
    <Stack className={styles.root}>
      <Stack alignItems="start" gap={6} p={6}>
        <Typography className={styles.title} typography="fontH0">
          {title ?? defaultTitle}
        </Typography>
        <Typography className={styles.subTitle}>{subTitle ?? defaultSubTitle}</Typography>
        <Typography className={styles.subTitle}>{description ?? defaultDescription}</Typography>
      </Stack>
    </Stack>
  );
};

export default MaintenancePlaceholder;
