import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { IDefaultResponse } from "@skm-shared/api";
import { DASHBOARD_LAST_UPDATE_ROUTES, ILastUpdate } from "@skm-shared/dashboard-last-update";

import { apiFetch } from "@shared/api/api-fetch";
import { queryClient } from "@shared/api/queryClient";

export const useGetLastUpdateMonitors = () =>
  useQuery({
    queryKey: ["last-update-monitors"],
    queryFn: () => apiFetch<IDefaultResponse<ILastUpdate[]>>(DASHBOARD_LAST_UPDATE_ROUTES.getAll(), { method: "GET" }),
    select: data => data.data
  });

export const useAddLastUpdateMonitor = () =>
  useMutation({
    mutationKey: ["add-last-update-monitor"],
    mutationFn: (dto: Omit<ILastUpdate, "id">) =>
      apiFetch<IDefaultResponse>(DASHBOARD_LAST_UPDATE_ROUTES.add(), { data: dto, method: "POST" }),
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: ["last-update-monitors"] });
      toast.success("Обновление монитора добавлено");
    }
  });

export const useUpdateLastUpdateMonitor = () =>
  useMutation({
    mutationKey: ["update-last-update-monitor"],
    mutationFn: (dto: ILastUpdate) =>
      apiFetch<IDefaultResponse>(DASHBOARD_LAST_UPDATE_ROUTES.update(), { method: "PATCH", data: dto }),
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: ["last-update-monitors"] });
      toast.success("Обновление монитора обновлено");
    }
  });

export const useRemoveLastUpdateMonitor = () =>
  useMutation({
    mutationKey: ["remove-last-update-monitor"],
    mutationFn: (id: string) =>
      apiFetch<IDefaultResponse>(DASHBOARD_LAST_UPDATE_ROUTES.remove(id), { method: "DELETE" }),
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: ["last-update-monitors"] });
      toast.success("Обновление монитора удалено");
    }
  });
