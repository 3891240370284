import { ILastUpdate } from "../dashboard-last-update";
import { IRole } from "../user";

export interface IRoute {
  children: IRoute[];
  contentStatus: TreeContentStatus;
  element?: string;
  frame?: IFrameNode;
  fullTitle: string;
  hasDivider: boolean;
  id: string;
  isDefaultRoute: boolean;
  isLayout: boolean;
  maintenanceDesc?: string;
  path: string;
  priority: number;
  title: string;
}

export interface INode {
  componentName?: string;
  contentStatus: TreeContentStatus;
  contentType: TreeContentType;
  externalSrc?: string;
  host?: string;
  fullTitle: string;
  hasDivider: boolean;
  isDefaultRoute: boolean;
  id: string;
  lastUpdates: ILastUpdate[];
  maintenanceDesc?: string;
  mobileExternalSrc?: string;
  parentId?: string | null;
  path: string;
  priority: number;
  responsible?: string;
  responsibleLink?: string;
  roles: IRole[];
  title: string;
}

export enum TreeContentType {
  SKM = "SKM",
  IFRAME = "IFRAME"
}

export enum TreeContentStatus {
  WORK = "WORK",
  MAINTENANCE = "MAINTENANCE",
  DEVELOP = "DEVELOP"
}

export interface IFrameNode {
  externalSrc?: string;
  host?: string;
  mobileExternalSrc?: string;
}

export type GetNodeQueryParams = {
  title?: string;
  limit?: string;
};
