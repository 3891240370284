import { Box, Typography, styled } from "@mui/material";

const StyledContainer = styled(Box)<{ $isActive: boolean }>`
  background-color: rgba(20, 25, 26, 0.08);
  border-radius: 4px;
  padding: 8px 12px;
  border: ${({ $isActive }) => ($isActive ? "1px solid rgba(20, 25, 26, 0.20)" : "none")};

  &:hover {
    background-color: rgba(20, 25, 26, 0.12);
    cursor: pointer;
  }
`;

interface Props {
  onChange: (src: string) => void;
  src: string;
  title: string;
  isActive: boolean;
}

const SubFrame = ({ onChange, src, title, isActive }: Props) => {
  return (
    <StyledContainer $isActive={isActive} onClick={() => onChange(src)}>
      <Typography>{title}</Typography>
    </StyledContainer>
  );
};

export default SubFrame;
