import { FormLabelWrapper } from "@brusnika.tech/ui-kit";
import { TextField } from "@mui/material";
import { debounce } from "radash";

import { useFilterContext } from "./Filters";

interface Props {
  name: string;
  label: string;
  labelPlacement: "top" | "left";
}

const FilterText = ({ name, label, labelPlacement }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value, onChange } = useFilterContext();

  return (
    <FormLabelWrapper label={label} labelAlignment="baseline" labelPlacement={labelPlacement}>
      <TextField
        sx={{ minWidth: 200 }}
        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access
        onChange={debounce({ delay: 1000 }, e => onChange({ ...value, [name]: e.target.value }))}
      />
    </FormLabelWrapper>
  );
};

export default FilterText;
