import { toast } from "react-toastify";

import { availableComponents } from "@entities/monitor/lib/available-component";

export const checkImport = async (componentName?: string) => {
  const Component = await availableComponents[componentName || ""];

  if (!Component || Component.default.name === "Error") {
    toast.error("Такого компонента не существует");
  } else {
    toast.success("Компонент подключен");
  }
};
