import { APIRoutes, IDefaultResponse } from "../api";

import { INode, IRoute } from "./monitors";

export interface MonitorsApi {
  getTree(...omitted: any[]): Promise<IRoute[]>;
  getNodes(...omitted: any[]): Promise<INode[]>;
  addNode(...omitted: any[]): Promise<IDefaultResponse<IRoute>>;
  updateNode(...omitted: any[]): Promise<IDefaultResponse<IRoute>>;
  removeNode(...omitted: any[]): Promise<IDefaultResponse<null>>;
}

export const PREFIX = "skm-trees";

export const SKM_TREE_ROUTES: APIRoutes<MonitorsApi> = {
  getTree: () => `${PREFIX}`,
  getNodes: () => `${PREFIX}/nodes`,
  addNode: () => `${PREFIX}`,
  updateNode: () => `${PREFIX}`,
  removeNode: (id: string) => `${PREFIX}/${id}`
};
