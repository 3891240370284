import { useEffect } from "react";

import { useGetConfig } from "@entities/config/api/config.service";
import { useConfigStore } from "@entities/config/model/config.store";
import { useGetUser } from "@entities/user/api/user.service";
import { useUserStore } from "@entities/user/model/user.store";

export const useInitialApp = () => {
  const { data: user, isLoading: isLoadingUser } = useGetUser();
  const { data: config, isLoading: isLoadingConfig } = useGetConfig();

  const setUser = useUserStore(state => state.setUser);
  const setConfig = useConfigStore(state => state.setConfig);

  const isLoading = isLoadingConfig || isLoadingUser;

  useEffect(() => {
    if (!isLoadingUser && user) {
      setUser(user);
    }

    if (!isLoadingConfig && config) {
      setConfig(config);
    }
  }, [isLoadingUser, isLoadingConfig, user, config, setUser, setConfig]);

  return { user, config, isLoading };
};
