import { FC, useMemo } from "react";

import { IconCrown, IconLock, IconProcessing, IconWrench } from "@brusnika.tech/ui-icons";
import { ListItemText, Stack } from "@mui/material";
import { TreeContentStatus } from "packages/shared/src/monitors";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";

import { SidebarNames, TreeItem } from "../types";

interface Props {
  item: TreeItem;
  sidebarName: SidebarNames;
}

const SidebarItem: FC<Props> = ({ item, sidebarName }) => {
  const { data: nodeData } = useGetMonitorNodes();
  const hashMapById = useMemo(() => nodeData?.hashMap("id"), [nodeData]);

  if (
    sidebarName === SidebarNames.CAMERAS ||
    sidebarName === SidebarNames.MAIN ||
    sidebarName === SidebarNames.ADMIN_MAIN
  ) {
    return <ListItemText primary={item.title} />;
  }

  return (
    <>
      <ListItemText
        primary={item.title}
        sx={{ color: hashMapById?.get(item.id)?.path.includes("//") ? "red" : undefined }}
      />

      {!!hashMapById?.get(item.id)?.roles.length && (
        <Stack title="Имеет роль">
          <IconLock iconSize="medium" />
        </Stack>
      )}

      {hashMapById?.get(item.id)?.isDefaultRoute && (
        <Stack title="Монитор по умолчанию">
          <IconCrown iconSize="medium" />
        </Stack>
      )}

      {hashMapById?.get(item.id)?.contentStatus === TreeContentStatus.DEVELOP && (
        <Stack title="В разработке">
          <IconProcessing iconSize="medium" />
        </Stack>
      )}

      {hashMapById?.get(item.id)?.contentStatus === TreeContentStatus.MAINTENANCE && (
        <Stack title="На тех. обслуживании">
          <IconWrench iconSize="medium" />
        </Stack>
      )}
    </>
  );
};

export default SidebarItem;
