import { useEffect, useMemo } from "react";

import { Spinner } from "@brusnika.tech/ui-kit";
import { INode, TreeContentStatus, TreeContentType } from "packages/shared/src/monitors";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  useAddNode,
  useGetMonitorNodes,
  useGetMonitorTree,
  useRemoveNode,
  useUpdateNode
} from "@entities/monitor/api/tree.service";
import { useGetRoles } from "@entities/role/api/role.service";
import { IManageTreeForm } from "@features/manage-tree-form/types";
import ManageTreeForm from "@features/manage-tree-form/ui/ManageTreeForm";
import { QueryParams, TopRoutes } from "@shared/consts";

const ManageMonitors = () => {
  const navigate = useNavigate();
  const { mutateAsync: mutateAdd } = useAddNode();
  const { mutate: mutateUpdate } = useUpdateNode();
  const { mutateAsync: mutateRemove } = useRemoveNode();
  const { data: roles = [] } = useGetRoles();
  const { data: tree = [] } = useGetMonitorTree();
  const { data: nodeData } = useGetMonitorNodes();

  const [searchParams, setSearchParams] = useSearchParams();
  const nodeParam = searchParams.get(QueryParams.nodeId) ?? undefined;
  const hashMapById = useMemo(() => nodeData?.hashMap("id"), [nodeData]);

  const currentNode = hashMapById?.get(nodeParam ?? "");
  const parentNode = hashMapById?.get(currentNode?.parentId ?? "");
  const firstNode = `/${TopRoutes.ADMIN_MONITOR_TREE}?${QueryParams.nodeId}=${tree[0].id}`;

  const onSubmit = (form: IManageTreeForm) => {
    const rolesIds = form.roles.map(r => r.src);

    const dto: INode = {
      ...form,
      componentName: form.componentName?.src,
      priority: Number(form.priority),
      roles: roles.filter(r => rolesIds.includes(r.id)),
      host: form.host?.src,
      parentId: form.parent?.src
    };

    mutateUpdate(dto);
  };

  const onAddNode = async (node: INode) => {
    try {
      const { data } = await mutateAdd({
        componentName: "IFramePage",
        parentId: node.id,
        contentType: TreeContentType.IFRAME,
        path: `${node.path}/mock`,
        priority: node.priority,
        contentStatus: TreeContentStatus.WORK,
        hasDivider: false,
        isDefaultRoute: false,
        roles: [],
        lastUpdates: [],
        title: `${node.title} child`,
        fullTitle: `${node.title} child`
      });
      setSearchParams(params => {
        params.set(QueryParams.nodeId, data.id);
        return params;
      });
    } catch (e) {
      toast.error(e as never);
    }
  };

  const onRemoveNode = async (node: INode) => {
    try {
      const { parentId, id } = node;
      await mutateRemove(id);
      if (parentId) {
        setSearchParams(params => {
          params.set(QueryParams.nodeId, parentId);
          return params;
        });
      }
    } catch (e) {
      toast.error(e as never);
    }
  };

  useEffect(() => {
    if (!searchParams.size) {
      navigate(firstNode, { replace: true });
    }
  }, [firstNode, navigate, searchParams.size]);

  if (!currentNode) {
    return <Spinner size="large" text="Загрука" />;
  }

  return (
    <ManageTreeForm
      node={currentNode}
      parentNode={parentNode}
      roles={roles}
      onAdd={onAddNode}
      onRemove={onRemoveNode}
      onSubmit={onSubmit}
    />
  );
};

export default ManageMonitors;
