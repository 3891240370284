import { FC } from "react";

import { Button, FormControlLabel, MultiSelect } from "@brusnika.tech/ui-kit";
import { Stack, TextField } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { IRole } from "@skm-shared/user";

import { IUserCreateForm } from "@entities/user/types";
import { getSelectElement, isOptionEqualToValue } from "@shared/lib/utils";

interface Props {
  roles: IRole[];
  onSubmit: (form: IUserCreateForm) => void;
}

const UserCreateForm: FC<Props> = ({ roles, onSubmit }) => {
  const { register, control, reset, handleSubmit } = useForm<IUserCreateForm>();
  const roleOptions = roles.map(r => getSelectElement(r.id, r.name));

  return (
    <Stack alignItems="end" direction="row">
      <FormControlLabel control={<TextField {...register("email")} />} label="E-mail" labelPlacement="top" />

      <FormControlLabel
        control={
          <Controller
            control={control}
            name={"roles"}
            render={({ field }) => (
              <MultiSelect
                {...field}
                isOptionEqualToValue={isOptionEqualToValue}
                options={roleOptions}
                sx={{ width: 250 }}
                onChange={(_, value) => field.onChange(value)}
              />
            )}
          />
        }
        label="Роли"
        labelPlacement="top"
      />

      <Button
        variant="filled"
        onClick={e => {
          void handleSubmit(onSubmit)(e);
          reset({ roles: undefined, email: "" });
        }}
      >
        Создать
      </Button>
    </Stack>
  );
};

export default UserCreateForm;
