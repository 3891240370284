import { FC, useState } from "react";

import { FloatingButton, IconComment, JiraIssueCollector } from "@brusnika.tech/jira-issue-collector";

import { IUser } from "@skm-shared/user";

interface Props {
  user: IUser;
}

const JiraIssue: FC<Props> = ({ user }) => {
  const [openDialog, setOpenDialog] = useState(false);

  return (
    <>
      {openDialog && (
        <JiraIssueCollector
          projectName={"skm"}
          userInfo={{ email: user.email, name: user.name ?? "" }}
          onClose={() => {
            setOpenDialog(false);
          }}
        />
      )}

      <FloatingButton buttonStyle={{ bottom: "12px", right: "14px" }} onClick={() => setOpenDialog(true)}>
        <IconComment />
      </FloatingButton>
    </>
  );
};

export default JiraIssue;
