import { useQuery } from "@tanstack/react-query";

import { GetCameraNodeQueryParams, ICameraNode, ICameraRoute } from "@skm-shared/cameras/cameras";

import { apiFetch } from "@shared/api/api-fetch";

export const CAMERA_TREE_QUERY_KEYS = {
  camerasTree: "cameras-tree",
  treeCameraNodes: "tree-camera-nodes"
};

export const useGetCameraTree = (opts?: { disabled: boolean }) =>
  useQuery({
    queryKey: [CAMERA_TREE_QUERY_KEYS.camerasTree],
    queryFn: () => apiFetch<ICameraRoute[]>("cameras-tree", { method: "GET" }),
    staleTime: 60000,
    gcTime: 60000,
    enabled: !opts?.disabled,
    select: ({ data }) => data
  });

export const useGetCameraNodes = (params?: GetCameraNodeQueryParams) =>
  useQuery({
    queryKey: [CAMERA_TREE_QUERY_KEYS.treeCameraNodes, params?.title],
    queryFn: () => apiFetch<ICameraNode[]>("camera-nodes", { method: "GET", params }),
    staleTime: 60000,
    gcTime: 60000,
    select: ({ data }) => {
      const mapById = new Map<string, ICameraNode>();
      const mapByPath = new Map<string, ICameraNode>();

      for (const node of data) {
        mapById.set(node.id, node);
        mapByPath.set(node.path, node);
      }

      return { list: data, mapById, mapByPath };
    }
  });
