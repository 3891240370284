import { useEffect, useMemo, useState } from "react";

import { Stack } from "@mui/material";
import { useSearchParams } from "react-router-dom";

import { useGetMonitorNodes, useGetMonitorTree } from "@entities/monitor/api/tree.service";
import { QueryParams } from "@shared/consts";
import { NestedTree } from "@shared/ui/tree/NestedTree";
import { createFolder, linksHandler } from "@widgets/sidebars/lib";
import { SidebarNames, TreeItem } from "@widgets/sidebars/types";
import SidebarItem from "@widgets/sidebars/ui/SidebarItem";

const AdminMonitorsSidebar = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: routes = [] } = useGetMonitorTree();
  const { data: nodeData, isLoading } = useGetMonitorNodes();

  const [selectedId, setSelectedId] = useState<string>("");

  const hashMapById = useMemo(() => nodeData?.hashMap("id"), [nodeData]);
  const active = hashMapById?.get(searchParams.get(QueryParams.nodeId) ?? "");
  const treeEntry = useMemo(() => {
    return createFolder({
      id: "root",
      text: "root",
      children: routes.map(item => linksHandler(item, SidebarNames.ADMIN_TREE)) ?? []
    });
  }, [routes]);

  const handleItemClick = (item: TreeItem) => {
    const node = hashMapById?.get(item.id);
    if (!node) return;

    setSearchParams(params => {
      params.set(QueryParams.nodeId, item.id);
      return params;
    });
    setSelectedId(item.id);
  };

  useEffect(() => {
    if (active) setSelectedId(active.id);
  }, [active]);

  if (isLoading) {
    return null;
  }

  return (
    <Stack>
      <NestedTree<TreeItem>
        isSelected={item => item.id === selectedId}
        item={treeEntry}
        itemContent={item => <SidebarItem item={item} sidebarName={SidebarNames.ADMIN_TREE} />}
        name={SidebarNames.ADMIN_TREE}
        openedIds={nodeData?.list.map(item => item.id)}
        paddingLeftMap={(_, theme, level, isDesktop) => theme.spacing((isDesktop ? 4 : 2) + 4 * (level - 1))}
        onItemClick={handleItemClick}
      />
    </Stack>
  );
};

export default AdminMonitorsSidebar;
