import { ComponentType } from "react";

// eslint-disable-next-line
const getDynamicComponent = async (componentName: string): Promise<{ default: ComponentType<any> }> => {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return await import(`../imports/${componentName}.default.ts`);
  } catch (e) {
    return import(`../imports/ErrorPage.default`);
  }
};

export const MONITOR_PAGES = {
  MAIN: "MainPage",
  IFRAME: "IFramePage"
};

// eslint-disable-next-line
export const availableComponents: Record<(typeof MONITOR_PAGES)[keyof typeof MONITOR_PAGES], Promise<{ default: ComponentType<any> }>> = {
  [MONITOR_PAGES.MAIN]: getDynamicComponent(MONITOR_PAGES.MAIN),
  [MONITOR_PAGES.IFRAME]: getDynamicComponent(MONITOR_PAGES.IFRAME)
};
