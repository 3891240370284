import { FC } from "react";

import { IconTree } from "@brusnika.tech/ui-icons";
import { useLayout } from "@brusnika.tech/ui-portal";
import { Stack, Typography } from "@mui/material";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { formatDate, grayColor } from "@shared/lib/utils";

interface Props {
  nodeId: string;
}

const MonitorHeader: FC<Props> = ({ nodeId }) => {
  const { data: nodeData } = useGetMonitorNodes();
  const node = nodeData?.hashMap("id").get(nodeId);
  const { toggleLeftDrawer, isDesktop } = useLayout();

  return (
    <Stack alignItems="center" direction="row" justifyContent="space-between" width="100%">
      <Stack alignItems="center" direction="row" gap={1}>
        {!isDesktop && <IconTree iconSize="medium" onClick={toggleLeftDrawer} />}

        <Typography fontSize={isDesktop ? 18 : 16} fontWeight="bold">
          {node?.fullTitle || node?.title}
        </Typography>
      </Stack>

      <Stack alignItems="center" direction="row">
        <Stack alignItems="start">
          {node?.responsible && (
            <Typography color={grayColor} fontSize={10} paddingX={2}>
              Ответственный: {node.responsible}
            </Typography>
          )}

          {node && node.lastUpdates.length > 0 && (
            <Typography color={grayColor} fontSize={10} paddingX={2}>
              Обновлено: {formatDate(node.lastUpdates[0].datetime, "DD.MM.YYYY HH:mm")}
            </Typography>
          )}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default MonitorHeader;
