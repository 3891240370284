import { useEffect, useState } from "react";

interface Props {
  src: string;
  alt: string;
}

const ActiveFrame = ({ src, alt }: Props) => {
  const [isFrame, setIsFrame] = useState<boolean>(false);

  useEffect(() => {
    setIsFrame(false);
  }, [src]);

  if (isFrame) {
    return (
      <iframe
        src={src}
        style={{ border: "none", width: "100%", aspectRatio: "16 / 9", height: "100%", backgroundColor: "#fff" }}
      />
    );
  }

  return (
    <img
      alt={alt}
      src={src}
      style={{ border: "none", width: "100%", aspectRatio: "16 / 9", height: "100%", backgroundColor: "#fff" }}
      onError={() => {
        setIsFrame(true);
      }}
      onLoad={() => {
        setIsFrame(false);
      }}
    />
  );
};

export default ActiveFrame;
