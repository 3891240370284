import { useEffect, useMemo, useState } from "react";

import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { useGetMonitorNodes, useGetMonitorTree } from "@entities/monitor/api/tree.service";
import { useMonitorTreeStore } from "@entities/monitor/model/monitor-tree.store";
import { NestedTree } from "@shared/ui/tree/NestedTree";
import { createFolder, linksHandler } from "@widgets/sidebars/lib";
import { SidebarNames, TreeItem } from "@widgets/sidebars/types";
import SidebarItem from "@widgets/sidebars/ui/SidebarItem";

const MonitorSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const setActiveNode = useMonitorTreeStore(state => state.setActiveNode);

  const { data: routes = [] } = useGetMonitorTree();
  const { data: nodeData, isLoading } = useGetMonitorNodes();

  const [selectedId, setSelectedId] = useState<string>("");

  const hashMapById = useMemo(() => nodeData?.hashMap("id"), [nodeData]);
  const hashMapByPath = useMemo(() => nodeData?.hashMap("path"), [nodeData]);

  const treeEntry = useMemo(() => {
    return createFolder({
      id: "root",
      text: "root",
      children: routes[0]?.children.map(item => linksHandler(item, SidebarNames.MAIN)) ?? []
    });
  }, [routes]);

  const onSelectItem = (id: string) => {
    setSelectedId(id);
    setActiveNode(id);
  };

  const handleItemClick = (item: TreeItem) => {
    const node = hashMapById?.get(item.id);
    if (!node) return;

    navigate(node.path);
    onSelectItem(item.id);
  };

  useEffect(() => {
    if (nodeData) {
      const active = hashMapByPath?.get(location.pathname);
      if (active) {
        onSelectItem(active.id);
      }
    }
  }, [location.pathname, isLoading]);

  return (
    <Stack>
      <NestedTree<TreeItem>
        isSelected={item => item.id === selectedId}
        item={treeEntry}
        itemContent={item => <SidebarItem item={item} sidebarName={SidebarNames.MAIN} />}
        name={SidebarNames.MAIN}
        paddingLeftMap={(_, theme, level, isDesktop) => theme.spacing((isDesktop ? 4 : 2) + 4 * (level - 1))}
        onItemClick={handleItemClick}
      />
    </Stack>
  );
};

export default MonitorSidebar;
