import { APIRoutes, IDefaultResponse } from "../api";

import { AppConfig } from "./config";

export interface ConfigApi {
  getConfig(): IDefaultResponse<AppConfig>;
}

export const PREFIX = "config";

export const CONFIG_ROUTES: APIRoutes<ConfigApi> = {
  getConfig: () => `${PREFIX}`
};
