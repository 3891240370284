import { useEffect, useMemo, useState } from "react";

import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { useConfigStore } from "@entities/config/model/config.store";
import { AdminRoutes } from "@shared/consts";
import { NestedTree } from "@shared/ui/tree/NestedTree";
import { createFolder } from "@widgets/sidebars/lib";
import { SidebarNames, TreeItem } from "@widgets/sidebars/types";
import SidebarItem from "@widgets/sidebars/ui/SidebarItem";

const AdminSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const config = useConfigStore(state => state.config);

  const [selectedId, setSelectedId] = useState<string>("");

  const treeEntry = useMemo(() => {
    const items: TreeItem[] = [
      { title: "Роли", children: [], id: AdminRoutes.ROLES },
      { title: "Пользователи", children: [], id: AdminRoutes.USERS },
      { title: "Посещения", children: [], id: AdminRoutes.VISITS }
    ];

    if (config.ERP_ENABLED) {
      items.push({ title: "Обновления мониторов", children: [], id: AdminRoutes.UPDATE_MONITOR });
    }
    return createFolder({
      id: "root",
      text: "root",
      children: items
    });
  }, [config.ERP_ENABLED]);

  const handleItemClick = (item: TreeItem) => {
    navigate(item.id);
    setSelectedId(item.id);
  };

  useEffect(() => {
    setSelectedId(location.pathname.split("/").at(-1) ?? "");
  }, [location.pathname]);

  return (
    <Stack>
      <NestedTree<TreeItem>
        isSelected={item => item.id === selectedId}
        item={treeEntry}
        itemContent={item => <SidebarItem item={item} sidebarName={SidebarNames.MAIN} />}
        name={SidebarNames.MAIN}
        paddingLeftMap={(_, theme, level, isDesktop) => theme.spacing((isDesktop ? 4 : 2) + 4 * (level - 1))}
        onItemClick={handleItemClick}
      />
    </Stack>
  );
};

export default AdminSidebar;
