import { useEffect, useMemo, useState } from "react";

import { Grid, Stack } from "@mui/material";

import { ICameraRoute } from "@skm-shared/cameras/cameras";

import { useVisitPage } from "@entities/user/lib/useVisitPage";
import ActiveFrame from "@features/camera-frame/ui/ActiveFrame";
import SubFrame from "@features/camera-frame/ui/SubFrame";

interface Props {
  route: ICameraRoute;
}

const IFrameCameraPage = ({ route }: Props) => {
  useVisitPage(route);
  const frames: string[] = useMemo(() => route.frame?.src ?? [], [route.frame?.src]);
  const [activeFrame, setFrame] = useState<string>("");

  useEffect(() => {
    setFrame(frames[0]);
  }, [frames]);

  const handleChange = (src: string) => {
    setFrame(src);
  };

  return (
    <Grid container p={2} spacing={2}>
      <Grid item xs={10}>
        <ActiveFrame alt={""} src={activeFrame} />
      </Grid>

      <Grid item xs={2}>
        <Stack gap={2}>
          {frames.map((frameUrl, index) => (
            <SubFrame
              isActive={frameUrl === activeFrame}
              key={index}
              src={frameUrl}
              title={`${route.title} камера ${index + 1}`}
              onChange={handleChange}
            />
          ))}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default IFrameCameraPage;
