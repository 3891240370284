import { useEffect, useMemo, useRef } from "react";

import { ymHit } from "@brusnika.tech/ym-wrapper";
import { IRoute } from "packages/shared/src/monitors";

import { ICameraRoute } from "@skm-shared/cameras/cameras";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { useAddVisit } from "@entities/user/api/user-visits.service";
import { useGetUser } from "@entities/user/api/user.service";
import { YANDEX_COUNTER } from "@shared/consts";

export const useVisitPage = (route: IRoute | ICameraRoute) => {
  const { data: nodeData } = useGetMonitorNodes();
  const { data: user } = useGetUser();
  const { mutate } = useAddVisit();

  const nodeHashMap = useMemo(() => nodeData?.hashMap("id"), [nodeData]);
  const spentTime = useRef<number>(0);

  useEffect(() => {
    if (user) {
      ymHit({ userEmail: user.email, counterId: YANDEX_COUNTER });
    }

    const interval = setInterval(() => {
      spentTime.current += 1000;
    }, 1000);

    return () => {
      clearInterval(interval);

      if (user && nodeHashMap && spentTime.current > 5000) {
        mutate({
          spentTime: spentTime.current,
          page: nodeHashMap.get(route.id)!
        });
        spentTime.current = 0;
      }
    };
  }, [mutate, nodeHashMap, route.id, user]);
};
