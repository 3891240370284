import { Button } from "@brusnika.tech/ui-kit";
import { useModal } from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";

import { useAddRole, useAddRoleUsers } from "@entities/role/api/role.service";
import { AddRoleUsersFormType, IRoleCreateForm } from "@widgets/role/types";
import AssignRoleModal from "@widgets/role/ui/AssignRoleModal";
import RoleCreateModal from "@widgets/role/ui/RoleCreateModal";

const RoleActions = () => {
  const { mutateAsync: mutateAddRole } = useAddRole();
  const { mutateAsync: mutateAddRoleUsers } = useAddRoleUsers();

  const createRoleModal = useModal(RoleCreateModal);
  const assignRoleModal = useModal(AssignRoleModal);

  const onAddSubmit = (form: IRoleCreateForm) => {
    void mutateAddRole(form).then(() => createRoleModal.hide());
  };

  const onAddRoleUserSubmit = (form: AddRoleUsersFormType) => {
    if (!form.role?.src || !form.users.length) return;

    void mutateAddRoleUsers({
      roleId: form.role.src,
      users: form.users.map(u => u.src ?? "")
    }).then(() => assignRoleModal.hide());
  };

  return (
    <Stack direction="row" gap={2}>
      <Button variant="filled" onClick={() => createRoleModal.show({ onSubmit: onAddSubmit })}>
        Создать роль
      </Button>

      <Button variant="filled" onClick={() => assignRoleModal.show({ onSubmit: onAddRoleUserSubmit })}>
        Присвоить роль
      </Button>
    </Stack>
  );
};

export default RoleActions;
