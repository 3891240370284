import { create } from "zustand";

type State = {
  activeNode: string | null;
  isAdmin: boolean;
};

type Actions = {
  setActiveNode: (id: string) => void;
  setIsAdmin: (v: boolean) => void;
};

export const useMonitorTreeStore = create<State & Actions>(set => ({
  activeNode: null,
  isAdmin: false,
  setIsAdmin: v => set(prev => ({ ...prev, isAdmin: v })),
  setActiveNode: (id: string) => {
    set(prev => ({ ...prev, activeNode: id }));
  }
}));
