import { FC, PropsWithChildren, useLayoutEffect, useRef } from "react";

interface Props extends PropsWithChildren {
  isEnabled: boolean;
  onChange: (v: boolean) => void;
}

const Fullscreen: FC<Props> = ({ isEnabled, children, onChange }) => {
  const ref = useRef<HTMLDivElement>(null);

  const toggleFullScreen = (isEnabled: boolean, element: HTMLDivElement) => {
    if (isEnabled && !!document.fullscreenElement) {
      void document.exitFullscreen();
      return;
    }

    if (isEnabled && !document.fullscreenElement) {
      void element.requestFullscreen();
      return;
    }

    if (!isEnabled && !!document.fullscreenElement) {
      void document.exitFullscreen();
      return;
    }
  };

  useLayoutEffect(() => {
    const element = ref.current;

    const changeListener = (_: Event) => {
      if (!document.fullscreenElement) {
        onChange(false);
      }
    };

    const errorListener = (_: Event) => {
      onChange(false);
    };

    element?.addEventListener("fullscreenchange", changeListener);
    element?.addEventListener("fullscreenerror", errorListener);

    return () => {
      element?.removeEventListener("fullscreenchange", changeListener);
      element?.removeEventListener("fullscreenerror", errorListener);
    };
  }, []);

  useLayoutEffect(() => {
    const element = ref.current;

    if (element) {
      toggleFullScreen(isEnabled, element);
    }
  }, [isEnabled]);

  return (
    <div ref={ref} style={{ width: "100%", height: "100%", display: "flex" }}>
      {children}
    </div>
  );
};

export default Fullscreen;
