import { useMutation } from "@tanstack/react-query";

import { IDefaultResponse } from "@skm-shared/api";

import { apiFetch } from "@shared/api/api-fetch";

export const useErrorLogs = () =>
  useMutation({
    mutationKey: ["error-log"],
    mutationFn: (dto: unknown) => apiFetch<IDefaultResponse>("config/errors", { method: "POST", data: dto })
  });
