import { APIRoutes, IDefaultResponse } from "../api";

import { IStatisticVisit } from "./statistic-visit";

export interface StatisticVisitApi {
  all(...omitted: any[]): Promise<IDefaultResponse<{ statistics: IStatisticVisit[]; all: number }>>;
  create(...omitted: any[]): Promise<IDefaultResponse<IStatisticVisit>>;
}

export const PREFIX = "statistic/visits";

export const STATISTIC_VISIT_ROUTES: APIRoutes<StatisticVisitApi> = {
  all: () => `${PREFIX}`,
  create: () => `${PREFIX}`
};
