import { Combobox, FormLabelWrapper, MultiCombobox } from "@brusnika.tech/ui-kit";

import { useFilterContext } from "@features/filters/ui/Filters";

interface Props {
  isMulti?: boolean;
  name: string;
  options: any[];
  label: string;
  labelPlacement: "top" | "left";
}

const FilterCombobox = ({ isMulti, name, options, label, labelPlacement }: Props) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const { value, onChange } = useFilterContext();

  const handleChange = (_: any, v: any) => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    onChange({ ...value, [name]: v });
  };

  if (isMulti) {
    return (
      <FormLabelWrapper label={label} labelAlignment="baseline" labelPlacement={labelPlacement}>
        {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
        <MultiCombobox options={options} sx={{ minWidth: 200 }} onChange={handleChange} />
      </FormLabelWrapper>
    );
  }

  return (
    <FormLabelWrapper label={label} labelAlignment="baseline" labelPlacement={labelPlacement}>
      {/* eslint-disable-next-line @typescript-eslint/no-unsafe-assignment */}
      <Combobox options={options} sx={{ minWidth: 200 }} onChange={handleChange} />
    </FormLabelWrapper>
  );
};

export default FilterCombobox;
