import { INode } from "packages/shared/src/monitors";

import { IManageTreeForm } from "@features/manage-tree-form/types";
import { getSelectElement } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";

export const manageTreeFormMapper = (node: INode, parentValue?: ISelectElement): IManageTreeForm => {
  const componentName = node.componentName ? getSelectElement(node.componentName, node.componentName) : undefined;
  const host = node.host ? getSelectElement(node.host, node.host) : undefined;

  return {
    parentId: node.parentId,
    title: node.title,
    fullTitle: node.fullTitle,
    id: node.id,
    lastUpdates: node.lastUpdates,
    responsibleLink: node.responsibleLink,
    path: node.path,
    contentStatus: node.contentStatus,
    maintenanceDesc: node.maintenanceDesc,
    priority: node.priority,
    hasDivider: node.hasDivider,
    isDefaultRoute: node.isDefaultRoute,
    contentType: node.contentType,
    responsible: node.responsible,
    externalSrc: node.externalSrc,
    mobileExternalSrc: node.mobileExternalSrc,
    componentName,
    host,
    parent: parentValue,
    roles: node.roles.map(r => ({ label: r.name, src: r.id }))
  };
};
