import { APIRoutes, IDefaultResponse } from "../api";

import { IComment } from "./dashboard-comments";

export interface DashboardCommentsApi {
  getById(...omitted: any[]): Promise<IDefaultResponse<IComment[]>>;
  add(...omitted: any[]): Promise<IDefaultResponse<IComment>>;
  update(...omitted: any[]): Promise<IDefaultResponse>;
  remove(...omitted: any[]): Promise<IDefaultResponse>;
}

export const PREFIX = "dashboard/comments";

export const DASHBOARD_COMMENTS_ROUTES: APIRoutes<DashboardCommentsApi> = {
  getById: () => `${PREFIX}`,
  add: () => `${PREFIX}`,
  update: () => `${PREFIX}`,
  remove: (id: string) => `${PREFIX}/${id}`
};
