import { ErrorInfo, FC, useEffect } from "react";

import { useErrorLogs } from "@entities/logs/api/log.service";

interface Props {
  error?: Error;
  errorInfo?: ErrorInfo;
}

const ErrorFallback: FC<Props> = ({ errorInfo, error }) => {
  const { mutate } = useErrorLogs();

  useEffect(() => {
    if (errorInfo && error) {
      mutate({ errorInfo: errorInfo.componentStack, error: error?.stack });
    }
  }, [error, errorInfo]);

  return null;
};

export default ErrorFallback;
