import { AuthenticationError, AxiosBFFAuth } from "@brusnika.tech/auth-bff-axios";
import axios, { AxiosRequestConfig, AxiosResponse, InternalAxiosRequestConfig } from "axios";

import { IUser } from "@skm-shared/user";

const api = axios.create();

api.interceptors.request.use(
  (rq: InternalAxiosRequestConfig): InternalAxiosRequestConfig => ({ ...rq, withCredentials: true })
);

export const auth = new AxiosBFFAuth<IUser>(api, {
  backendUrl: import.meta.env.VITE_BACKEND_URL,
  onUserUpdatedError(error: AuthenticationError): void {
    console.error(error);
  }
});

export async function apiFetch<T>(url: string, options: AxiosRequestConfig = {}): Promise<AxiosResponse<T>> {
  return api.request({
    url: `${import.meta.env.VITE_BACKEND_URL}/${url}`,
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      ...options?.headers
    },
    ...options
  });
}
