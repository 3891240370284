import { useEffect } from "react";

import { Spinner } from "@brusnika.tech/ui-kit";
import { useNavigate } from "react-router-dom";

import { useGetMonitorNodes, useGetMonitorTree } from "@entities/monitor/api/tree.service";
import { TopRoutes } from "@shared/consts";

const MainPage = () => {
  const navigate = useNavigate();
  const { data: tree = [] } = useGetMonitorTree();
  const { data: nodeData } = useGetMonitorNodes();

  const startRoute = tree.find(item => item.path === `/${TopRoutes.MAIN}`);

  useEffect(() => {
    if (startRoute && nodeData?.list.length) {
      const defaultRoute = nodeData.list.find(route => route.isDefaultRoute);
      const resultRoute = defaultRoute ?? startRoute.children[0];

      navigate(resultRoute.path, { replace: true });
    }
  }, [startRoute, navigate, nodeData?.list]);

  return <Spinner />;
};

export default MainPage;
