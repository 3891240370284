import { FC, useMemo, useState } from "react";

import { Search } from "@brusnika.tech/ui-kit";
import { useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { useMonitorTreeStore } from "@entities/monitor/model/monitor-tree.store";
import { getSelectElement, isOptionEqualToValue } from "@shared/lib/utils";

const SidebarSearch: FC = () => {
  const navigate = useNavigate();
  const { setActiveNode } = useMonitorTreeStore(useShallow(state => state));

  const [searchTerm, setSearchTerm] = useState<string>("");
  const filters = useMemo(() => {
    if (searchTerm.trim().length) {
      return { title: searchTerm, limit: "5" };
    }
    return undefined;
  }, [searchTerm]);

  const { data: allNodeData } = useGetMonitorNodes();
  const { data: nodeData, isLoading } = useGetMonitorNodes(filters);

  const hashMapById = useMemo(() => allNodeData?.hashMap("id"), [allNodeData]);

  const options = useMemo(
    () =>
      nodeData?.list.map(node =>
        getSelectElement(node.id, `${hashMapById?.get(node.parentId ?? "")?.title} -> ${node.title}`)
      ) ?? [],
    [hashMapById, nodeData?.list]
  );

  return (
    <Search
      inputValue={searchTerm}
      isOptionEqualToValue={isOptionEqualToValue}
      loading={isLoading}
      options={options}
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      placeholder="Поиск"
      style={{ width: 300 }}
      variant="dark"
      onChange={(_, v) => {
        const node = hashMapById?.get(v?.src ?? "");
        if (node) {
          navigate(node.path);
          setActiveNode(node.id);
        }
      }}
      onInputChange={(_, v) => setSearchTerm(v)}
    />
  );
};

export default SidebarSearch;
