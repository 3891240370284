import { useCallback, useEffect, useState } from "react";

import { IconArrowDown, IconArrowRight } from "@brusnika.tech/ui-icons";
import { useLayout } from "@brusnika.tech/ui-portal";
import {
  Box,
  Collapse,
  Divider,
  IconButton,
  List,
  ListItemButton,
  Stack,
  SxProps,
  Theme,
  useTheme
} from "@mui/material";

import { BaseListItem, ControlledNestedTreeProps, NestedTreeProps } from "./types";
import { getPathToNestedItem } from "./utils";

/**
 * Supercharged Nested Tree for navigation
 * @param props - An item is actually a tree-like structure with a generic
 * that should be derived from `BaseListItem`
 * Example usage:
 * ```
 * <NestedTree
 *    name={"versionsOpenedIds"}
 *    item={versions}
 *    onItemClick=
 *      { item => {
 *          if (item.data?.id) {
 *            onVersionClick(item.data.id);
 *          }
 *      }
 *    }
 *    createButtons={
 *      item=>
 *        <IconButton
 *           onClick={
 *             ()=>handleClick(item.id)
 *           }>
 *        </IconButton>}
 *    isSelected={item=>item.id===selectedItemId}
 *  />
 * ```
 * @returns a nested tree with collapse and bells and whistles
 */

export const NestedTree = <T extends BaseListItem>(props: NestedTreeProps<T>): JSX.Element => {
  const [openedIds, setOpenedIds] = useState<string[]>(props.openedIds ?? []);

  useEffect(() => {
    const foundIds = getPathToNestedItem<T>(props.item, props.isSelected);

    if (foundIds?.length) {
      setOpenedIds(prevIds => Array.from(new Set(prevIds.concat(foundIds))));
    }
  }, [props.isSelected, props.item]);

  return <ControlledNestedTree {...props} openedIds={openedIds} setOpenedIds={setOpenedIds} />;
};

/**
 * Technical implementation of the Controlled Nested Tree.
 * Controlled means a tree that does not remember its state.
 * So it expects a state and a callback to update the state to be passed down from
 * parent.
 */
export function ControlledNestedTree<T extends BaseListItem>({
  item,
  level = 0,
  onItemClick,
  itemContent,
  isSelected,
  selectedRef,
  chevronPosition = "left",
  chevronClosed,
  chevronOpened,
  paddingLeftMap,
  paddingRightMap,
  ...props
}: ControlledNestedTreeProps<T>): JSX.Element {
  const theme = useTheme();
  const { isDesktop } = useLayout();
  const isOpen = props.openedIds.includes(item.id);
  const isItemSelected = isSelected?.(item);
  const collapsible = item.children.length > 0;

  const handleToggle = () => {
    if (collapsible) {
      if (isOpen) {
        props.setOpenedIds(prevIds => prevIds.filter(id => id !== item.id));
      } else {
        props.setOpenedIds(prevIds => [...prevIds, item.id]);
      }
    }
  };

  const handleItemClick = () => {
    if (item && onItemClick) {
      onItemClick(item);
    }
  };

  const renderChildren = useCallback(
    (children: BaseListItem[]) =>
      children.map(child => (
        <ControlledNestedTree<T>
          chevronClosed={chevronClosed}
          chevronOpened={chevronOpened}
          chevronPosition={chevronPosition}
          isSelected={isSelected}
          item={child as T}
          itemContent={itemContent}
          key={child.id}
          level={level + 1}
          openedIds={props.openedIds}
          paddingLeftMap={paddingLeftMap}
          paddingRightMap={paddingRightMap}
          selectedRef={selectedRef}
          setOpenedIds={props.setOpenedIds}
          onItemClick={onItemClick}
        />
      )),
    [level, onItemClick, itemContent, isSelected, props.openedIds, props.setOpenedIds, selectedRef]
  );

  if (!item) {
    return <></>;
  }

  if (level === 0) {
    return <List disablePadding>{renderChildren(item.children)}</List>;
  }

  const chevron = collapsible ? (
    <IconButton size="small" onClick={() => handleToggle()}>
      {isOpen
        ? (chevronOpened ?? (
            <IconArrowDown iconSize={"medium"} sx={{ color: theme => theme.colors.icon.colorIconSecondary }} />
          ))
        : (chevronClosed ?? (
            <IconArrowRight iconSize={"medium"} sx={{ color: theme => theme.colors.icon.colorIconSecondary }} />
          ))}
    </IconButton>
  ) : (
    <Box sx={{ width: theme => theme.spacing(4) }}></Box>
  );

  const paddedSx: SxProps<Theme> = {
    position: "relative",
    paddingLeft:
      paddingLeftMap?.(item, theme, level, isDesktop) ?? theme.spacing((isDesktop ? 6 : 4) + 6 * (level - 1)),
    paddingRight: paddingRightMap?.(item, theme, level, isDesktop) ?? theme.spacing(isDesktop ? 3 : 3)
  };

  return (
    <>
      {item.hasDivider && <Divider style={{ margin: 0 }} />}

      <ListItemButton
        ref={isItemSelected ? selectedRef : null}
        selected={isItemSelected}
        sx={paddedSx}
        onClick={() => {
          if (item.isCollapsible) {
            handleToggle();
          } else {
            handleItemClick();
          }
        }}
      >
        <Stack
          alignItems={"center"}
          direction={chevronPosition === "right" ? "row-reverse" : "row"}
          flex="1"
          gap={2}
          justifyContent={chevronPosition === "right" ? "space-between" : "start"}
        >
          {chevronPosition !== "hidden" && chevron}
          {itemContent?.(item) ?? null}
        </Stack>
      </ListItemButton>

      {collapsible && (
        <Collapse unmountOnExit in={isOpen} timeout="auto">
          {renderChildren(item.children)}
        </Collapse>
      )}
    </>
  );
}
