import { z } from "zod";

export const configSchema = z
  .object({
    language: z.string(),
    tolgeeApiUrl: z.string(),
    tolgeeKey: z.string(),
    featureFlags: z.object({
      org: z.string()
    })
  })
  .strict()
  .required();

export type ConfigType = z.infer<typeof configSchema>;
export const defaultConfig: ConfigType = {
  language: "ru",
  tolgeeApiUrl: "https://i18n.brusnica.tech",
  tolgeeKey: (import.meta.env?.VITE_TOLGEE_KEY as string | undefined) ?? "",
  featureFlags: { org: "brusnika" }
};
