import { FC } from "react";

import { FormControlLabel, MultiSelect, Radio, RadioGroup, Select } from "@brusnika.tech/ui-kit";
import { useLayout } from "@brusnika.tech/ui-portal";
import { Stack, TextField, Typography } from "@mui/material";
import { INode, TreeContentStatus, TreeContentType } from "packages/shared/src/monitors";
import { alphabetical } from "radash";
import { Controller, useFormContext } from "react-hook-form";

import { IRole } from "@skm-shared/user";

import { useConfigStore } from "@entities/config/model/config.store";
import { MONITOR_PAGES } from "@entities/monitor/lib/available-component";
import { IManageTreeForm } from "@features/manage-tree-form/types";
import { getSelectElement, isOptionEqualToValue } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";
import CheckboxWithLabel from "@shared/ui/checkbox-with-label";

interface Props {
  parentNode?: INode;
  roles: IRole[];
}

const FormExtraInfo: FC<Props> = ({ parentNode, roles }) => {
  const config = useConfigStore(state => state.config);
  const { register, control, watch } = useFormContext<IManageTreeForm>();

  const { isDesktop } = useLayout();
  const rowDirection = isDesktop ? "row" : "column";
  const rowAlign = isDesktop ? "end" : "start";

  const pageOptions = alphabetical(
    Object.values(MONITOR_PAGES).map<ISelectElement>(key => getSelectElement(key, key)),
    item => item.label
  );
  const roleOptions = alphabetical(
    roles.map(r => getSelectElement(r.id, `${r.name} (${r.level} уровень)`)),
    item => item.label
  );

  return (
    <>
      <Stack alignItems={rowAlign} direction={rowDirection}>
        <Stack direction="column" gap={1} width={286}>
          <Typography fontSize={12}>Тип контента страницы</Typography>

          <Controller
            control={control}
            name="contentType"
            render={({ field: { onChange, value, name } }) => (
              <RadioGroup row name={name} sx={{ marginBottom: 0 }} value={value} onChange={(_, v) => onChange(v)}>
                <FormControlLabel
                  control={<Radio value={TreeContentType.SKM} />}
                  disabled={!parentNode}
                  label={"СКМ"}
                />
                <FormControlLabel
                  control={<Radio value={TreeContentType.IFRAME} />}
                  disabled={!parentNode}
                  label={"FRAME"}
                />
              </RadioGroup>
            )}
          />
        </Stack>

        <FormControlLabel
          control={
            <Controller
              control={control}
              name="componentName"
              render={({ field: { onChange, value } }) => (
                <Select
                  isOptionEqualToValue={isOptionEqualToValue}
                  options={pageOptions}
                  readOnly={watch("contentType") === TreeContentType.IFRAME || !parentNode}
                  sx={{ width: 270 }}
                  value={value}
                  onChange={(_, v) => onChange(v)}
                />
              )}
            />
          }
          label="Компонент"
          labelPlacement="top"
        />

        <FormControlLabel
          control={<TextField type="number" {...register("priority")} sx={{ width: 270 }} />}
          label="Приоритет"
          labelPlacement="top"
        />
      </Stack>

      <Stack direction={rowDirection}>
        <FormControlLabel
          control={<TextField sx={{ width: 270 }} {...register("responsible")} />}
          disabled={watch("contentType") === TreeContentType.SKM}
          label="Ответственный"
          labelPlacement="top"
        />

        <FormControlLabel
          control={<TextField sx={{ width: 270 }} {...register("responsibleLink")} />}
          disabled={watch("contentType") === TreeContentType.SKM}
          label="Паспорт"
          labelPlacement="top"
        />

        <FormControlLabel
          control={
            <Controller
              control={control}
              name="roles"
              render={({ field: { onChange, value } }) => (
                <MultiSelect
                  isOptionEqualToValue={isOptionEqualToValue}
                  options={roleOptions}
                  sx={{ width: 270 }}
                  value={value}
                  onChange={(_, v) => onChange(v)}
                />
              )}
            />
          }
          label="Роли"
          labelPlacement="top"
        />
      </Stack>

      <Stack alignItems={rowAlign} direction={rowDirection} gap={4}>
        <Stack direction="column" gap={1}>
          <Typography fontSize={12}>Статус монитора</Typography>

          <Controller
            control={control}
            name="contentStatus"
            render={({ field: { onChange, value, name } }) => (
              <RadioGroup row name={name} sx={{ marginBottom: 0 }} value={value} onChange={(_, v) => onChange(v)}>
                <FormControlLabel
                  control={<Radio value={TreeContentStatus.WORK} />}
                  disabled={!parentNode}
                  label={"Работает"}
                />
                <FormControlLabel
                  control={<Radio value={TreeContentStatus.MAINTENANCE} />}
                  disabled={!parentNode}
                  label={"Тех. обслуживание"}
                />
                <FormControlLabel
                  control={<Radio value={TreeContentStatus.DEVELOP} />}
                  disabled={!parentNode}
                  label={"В разработке"}
                />
              </RadioGroup>
            )}
          />
        </Stack>

        <Controller
          control={control}
          name="hasDivider"
          render={({ field: { name, value, onChange } }) => (
            <CheckboxWithLabel
              checked={value}
              label="Разделитель"
              labelPlacement="end"
              name={name}
              onChange={onChange}
            />
          )}
        />

        <Controller
          control={control}
          name="isDefaultRoute"
          render={({ field: { name, value, onChange } }) => (
            <CheckboxWithLabel
              checked={value}
              label="Монитор открывается по умолчанию"
              labelPlacement="end"
              name={name}
              onChange={onChange}
            />
          )}
        />
      </Stack>

      <Stack alignItems="start" direction={rowDirection}>
        <FormControlLabel
          control={<TextField multiline rows={4} sx={{ width: 270 }} {...register("maintenanceDesc")} />}
          disabled={watch("contentStatus") !== TreeContentStatus.MAINTENANCE}
          label="Описание тех. обслуживания"
          labelPlacement="top"
        />
      </Stack>

      <Stack direction="row">
        <Controller
          control={control}
          name="host"
          render={({ field: { value, onChange } }) => (
            <Select
              isOptionEqualToValue={isOptionEqualToValue}
              options={[
                { label: config.SUPERSET_URL, src: config.SUPERSET_URL },
                { label: config.POWER_BI_URL, src: config.POWER_BI_URL }
              ]}
              sx={{ width: 270 }}
              value={value}
              onChange={(_, v) => onChange(v)}
            />
          )}
        />
      </Stack>
    </>
  );
};

export default FormExtraInfo;
