import { create } from "zustand";

import { AppConfig, Environment } from "@skm-shared/config";

type State = {
  config: AppConfig;
};

type Actions = {
  setConfig: (config: AppConfig) => void;
  getConfig: () => AppConfig | undefined;
};

export const useConfigStore = create<State & Actions>((set, get) => ({
  config: {
    SUPERSET_URL: "",
    POWER_BI_URL: "",
    MODE: Environment.DEV,
    DOCUMENT_ENABLED: true,
    CAMERAS_ENABLED: true,
    ERP_ENABLED: true
  },
  setConfig: config => set(() => ({ config })),
  getConfig: () => get().config
}));
