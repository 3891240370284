import { FC, Fragment, useMemo } from "react";

import { IconBlock, IconCheck } from "@brusnika.tech/ui-icons";
import { Button, Combobox } from "@brusnika.tech/ui-kit";
import { Grid, TextField } from "@mui/material";
import { alphabetical } from "radash";
import { Controller, useForm } from "react-hook-form";

import { ILastUpdate } from "@skm-shared/dashboard-last-update";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { ILastUpdateFormUpdate } from "@features/update-monitor/types";
import { getSelectElement, isOptionEqualToValue } from "@shared/lib/utils";
import { formatDate } from "@shared/lib/utils/formatDate";
import { ISelectElement } from "@shared/types";

interface Props {
  item: ILastUpdate & { title: string };
  onSubmit: (form: ILastUpdateFormUpdate) => void;
  onSetEdit: (id: string | null) => void;
}

const UpdateMonitorUpdateForm: FC<Props> = ({ item, onSubmit, onSetEdit }) => {
  const { data: nodeData } = useGetMonitorNodes();
  const hashMapById = useMemo(() => nodeData?.hashMap("id"), [nodeData]);
  const currentNode = hashMapById?.get(item.skmTreeNodeId ?? "");

  const options = useMemo(
    () =>
      alphabetical(
        nodeData?.list.map<ISelectElement>(node => {
          const parentNode = hashMapById?.get(node.parentId ?? "");
          const label = parentNode ? `${parentNode.title} -> ${node.title}` : node.title;
          return getSelectElement(node.id, label);
        }) ?? [],
        item => item.label
      ),
    [hashMapById, nodeData?.list]
  );

  const { control, register, handleSubmit } = useForm<ILastUpdateFormUpdate>({
    values: {
      ...item,
      skmTreeNodeId: currentNode ? getSelectElement(currentNode.id, currentNode.title) : undefined,
      datetime: formatDate(item.datetime, "DD.MM.YYYY HH:mm")
    }
  });

  return (
    <Fragment>
      <Grid item sm={4}>
        <TextField {...register("view")} fullWidth />
      </Grid>

      <Grid item sm={3}>
        <Controller
          control={control}
          name={"skmTreeNodeId"}
          render={({ field }) => (
            <Combobox
              {...field}
              fullWidth
              isOptionEqualToValue={isOptionEqualToValue}
              options={options}
              onChange={(_, value) => field.onChange(value)}
            />
          )}
        />
      </Grid>

      <Grid item sm={2}>
        <TextField {...register("datetime")} fullWidth />
      </Grid>

      <Grid item sm={3}>
        <Button startIcon={<IconCheck />} sx={{ minWidth: 20 }} onClick={handleSubmit(onSubmit)} />
        <Button startIcon={<IconBlock />} sx={{ minWidth: 20 }} onClick={() => onSetEdit(null)} />
      </Grid>
    </Fragment>
  );
};

export default UpdateMonitorUpdateForm;
