import { Divider, Stack } from "@mui/material";
import { toast } from "react-toastify";

import { useAddLastUpdateMonitor, useGetLastUpdateMonitors } from "@entities/update-monitor/api/update-monitor.service";
import { ILastUpdateFormCreate } from "@features/update-monitor/types";
import UpdateMonitorCreateForm from "@features/update-monitor/ui/UpdateMonitorCreateForm";
import UpdateMonitorList from "@features/update-monitor/ui/UpdateMonitorList";
import { checkDate } from "@widgets/update-monitor/utils";

const UpdateMonitor = () => {
  const { mutate: mutateAdd } = useAddLastUpdateMonitor();
  const { data: lastUpdates } = useGetLastUpdateMonitors();

  const onSubmit = (form: ILastUpdateFormCreate) => {
    const date = checkDate(form.datetime);

    if (!date) {
      toast.error("Введите дату в формате DD.MM.YYYY");
      return;
    }

    mutateAdd({
      view: form.view,
      datetime: date,
      skmTreeNodeId: form.skmTreeNodeId?.src
    });
  };

  return (
    <Stack gap={5}>
      <UpdateMonitorCreateForm onSubmit={onSubmit} />
      <Divider />
      <UpdateMonitorList items={lastUpdates?.data || []} />
    </Stack>
  );
};

export default UpdateMonitor;
