import { FC } from "react";

import { IconEdit, IconTrash } from "@brusnika.tech/ui-icons";
import { Button } from "@brusnika.tech/ui-kit";
import { Stack, Typography } from "@mui/material";

import { IComment } from "@skm-shared/dashboard-comments";

import { formatDate } from "@shared/lib/utils/formatDate";
import { grayColor } from "@shared/lib/utils/text-colors";

interface Props {
  comment: IComment;
  email: string;
  handleEdit: (commentId: string) => void;
  onRemove: (commentId: string) => void;
}

const PageCommentItem: FC<Props> = ({ comment, email, handleEdit, onRemove }) => {
  return (
    <Stack gap={0.5} paddingY={1}>
      <Stack direction="row" justifyContent="space-between">
        <Typography color={grayColor} typography={"fontListItem2"}>
          {email}
        </Typography>

        <Typography color={grayColor} typography={"fontListItem2"}>
          {formatDate(comment.createdAt)}
        </Typography>
      </Stack>

      <Stack direction="row" justifyContent="space-between">
        <Typography typography={"fontListItem1"}>{comment.comment}</Typography>

        <Stack direction="row">
          <Button startIcon={<IconEdit />} sx={{ minWidth: 20 }} onClick={() => handleEdit(comment.id)} />
          <Button startIcon={<IconTrash />} sx={{ minWidth: 20 }} onClick={() => onRemove(comment.id)} />
        </Stack>
      </Stack>
    </Stack>
  );
};

export default PageCommentItem;
