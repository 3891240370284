import { Button, Dialog, FormControlLabel } from "@brusnika.tech/ui-kit";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";

import { IRoleCreateForm } from "@widgets/role/types";

interface Props {
  onSubmit: (form: IRoleCreateForm) => void;
}
const RoleCreateModal = NiceModal.create(({ onSubmit }: Props) => {
  const modal = useModal();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<IRoleCreateForm>();

  return (
    <Dialog open={modal.visible} title="Создать роль" onClose={modal.hide}>
      <Stack alignItems="end" gap={4}>
        <Stack direction="row" gap={4}>
          <FormControlLabel
            control={<TextField {...register("name", { required: true })} error={!!errors.name} />}
            label="Название"
            labelPlacement="top"
            sx={{ margin: 0 }}
          />

          <FormControlLabel
            control={
              <TextField
                {...register("level", { valueAsNumber: true, required: true })}
                error={!!errors.level}
                type="number"
              />
            }
            label="Приоритет"
            labelPlacement="top"
            sx={{ margin: 0 }}
          />
        </Stack>

        <Button
          variant="filled"
          onClick={e => {
            void handleSubmit(onSubmit)(e);
            reset({ level: 1, name: "" });
          }}
        >
          Создать
        </Button>
      </Stack>
    </Dialog>
  );
});

export default RoleCreateModal;
