import { createContext, PropsWithChildren, useContext } from "react";

interface Props<T = any> extends PropsWithChildren {
  value: T;
  onChange: (value: T) => void;
}

const FilterContext = createContext<Props>({ value: {}, onChange: () => ({}) });

export const useFilterContext = () => {
  const ctx = useContext(FilterContext);
  if (!ctx) throw new Error("Error filter context");
  return ctx;
};

// @tolgee-ignore
const Filters = <T = any,>({ children, value, onChange }: Props<T>) => {
  return <FilterContext.Provider value={{ value, onChange }}>{children}</FilterContext.Provider>;
};

export default Filters;
