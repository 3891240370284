import { useEffect } from "react";

import { Spinner } from "@brusnika.tech/ui-kit";
import { useNavigate } from "react-router-dom";

import { TopRoutes } from "@shared/consts";

const AdminMainPage = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate(`/${TopRoutes.ADMIN_MAIN}/roles`, { replace: true });
  }, [navigate]);

  return <Spinner text="Страница администрирования" />;
};

export default AdminMainPage;
