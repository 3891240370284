import { useEffect, useMemo, useState } from "react";

import { Button } from "@brusnika.tech/ui-kit";
import { Stack } from "@mui/material";
import {
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  RowData,
  useReactTable
} from "@tanstack/react-table";

import EditableCell from "@shared/ui/table/EditableCell";
import TableControls from "@shared/ui/table/controls/TableControls";
import { IColumn } from "@shared/ui/table/types";

import styles from "./index.module.scss";

declare module "@tanstack/react-table" {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  interface TableMeta<TData extends RowData> {
    updateData: (rowIndex: number, columnId: string, value: unknown) => void;
  }
}

interface Props<T extends object = any> {
  columns: IColumn<T>[];
  data: T[];
  onChange?: (rows: Record<number, T | undefined>) => void;
}

const Table = <T extends Record<string, any>>({ columns, data, onChange }: Props<T>) => {
  const [tData, setTData] = useState<T[]>([]);
  const [globalFilter, setGlobalFilter] = useState("");
  const [updatedRows, setUpdatedRows] = useState<Record<number, T | undefined>>({});

  const tColumns = useMemo(() => {
    const columnHelper = createColumnHelper<T>();

    return columns.map(column =>
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-expect-error
      columnHelper.accessor(column.accessor, {
        header: column.header,
        cell: column.isEditable
          ? info => <EditableCell cell={info} format={column.format} meta={column.meta} />
          : ({ getValue, row }) =>
              column.format
                ? column.format?.({ value: getValue<never>(), rowIndex: row.index, row: row.original })
                : getValue()
      })
    );
  }, [columns]);

  const table = useReactTable({
    data: tData,
    columns: tColumns,
    initialState: {
      pagination: { pageSize: 10 }
    },
    state: {
      globalFilter
    },
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    onGlobalFilterChange: setGlobalFilter,
    meta: {
      updateData: (rowIndex, columnId, value) => {
        setTData(old =>
          old.map((row, index) => {
            if (index === rowIndex && row[columnId] !== value) {
              const updatedRow = {
                ...old[rowIndex],
                [columnId]: value
              };

              handleChangeRow({ [rowIndex]: updatedRow });
              return updatedRow;
            }

            return row;
          })
        );
      }
    }
  });

  const handleChangeGlobalFilter = (value: string) => {
    setGlobalFilter(value);
  };

  const handleChangeRow = (value: Record<number, T | undefined>) => {
    setUpdatedRows(prev => ({ ...prev, ...value }));
  };

  useEffect(() => {
    setTData(data);
  }, [data]);

  return (
    <Stack className={styles.root} gap={4}>
      <TableControls handleChangeGlobalFilter={handleChangeGlobalFilter} table={table} />

      <table className={styles.table}>
        <thead>
          {table.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th className={styles.table_header} key={header.id}>
                  {header.isPlaceholder ? null : flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        <tbody>
          {table.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td className={styles.table_cell} key={cell.id}>
                  {flexRender(cell.column.columnDef.cell, cell.getContext())}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      {!!Object.keys(updatedRows).length && (
        <Stack direction="row" gap={2} justifyContent={"end"}>
          <Button
            variant="filled"
            onClick={() => {
              setTData(data);
              setUpdatedRows({});
            }}
          >
            Отменить
          </Button>

          <Button
            variant="filled"
            onClick={() => {
              onChange?.(updatedRows);
              setUpdatedRows({});
            }}
          >
            Сохранить
          </Button>
        </Stack>
      )}
    </Stack>
  );
};

export default Table;
