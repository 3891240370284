import { IRoute } from "packages/shared/src/monitors";

import { SidebarNames, TreeItem } from "@widgets/sidebars/types";

import { createFolder } from "./create-folder";
import { createItem } from "./create-item";

export const linksHandler = (route: IRoute, name: SidebarNames, parentId?: string): TreeItem => {
  if (route.children?.length) {
    const items = route.children.map(item => linksHandler(item, name, route.id));

    return createFolder({
      id: route.id,
      text: route.title,
      children: items,
      hasDivider: route.hasDivider,
      isCollapsible: name === SidebarNames.MAIN || name === SidebarNames.CAMERAS
    });
  }

  return createItem({
    id: route.id,
    text: route.title,
    hasDivider: route.hasDivider,
    parentId
  });
};
