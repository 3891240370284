import { StrictMode } from "react";

import { QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { createRoot } from "react-dom/client";

import { App } from "@app/index";
import { queryClient } from "@shared/api/queryClient";
import "react-toastify/dist/ReactToastify.css";

// eslint-disable-next-line
import type {} from "@brusnika.tech/ui-kit/theme/augmentation";
import "@brusnika.tech/ui-kit/style.css";

const root = createRoot(document.getElementById("root") as HTMLElement);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <App />
      {import.meta.env.VITE_MODE === "dev" && <ReactQueryDevtools />}
    </QueryClientProvider>
  </StrictMode>
);
