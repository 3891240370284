import { useMutation, useQuery } from "@tanstack/react-query";
import { toast } from "react-toastify";

import { IDefaultResponse } from "@skm-shared/api";
import { IAddRoleUsers, IRole, USER_ROUTES } from "@skm-shared/user";

import { MONITOR_TREE_QUERY_KEYS } from "@entities/monitor/api/tree.service";
import { apiFetch } from "@shared/api/api-fetch";
import { queryClient } from "@shared/api/queryClient";

export const useGetRoles = (keys?: unknown[]) =>
  useQuery({
    queryKey: (keys || []).concat(["roles"]),
    queryFn: () => apiFetch<IDefaultResponse<IRole[]>>(USER_ROUTES.getRoles(), { method: "GET" }),
    select: ({ data }) => data.data
  });

export const useAddRole = (keys?: unknown[]) =>
  useMutation({
    mutationKey: (keys || []).concat(["add-role"]),
    mutationFn: (role: Pick<IRole, "name" | "level">) =>
      apiFetch<IDefaultResponse<IRole>>(USER_ROUTES.addRole(), { method: "POST", data: role }),
    onSuccess({ data }) {
      void queryClient.invalidateQueries({ queryKey: ["roles"] });
      toast.success(data.message);
    }
  });

export const useAddRoleUsers = (keys?: unknown[]) =>
  useMutation({
    mutationKey: (keys || []).concat(["add-role-users"]),
    mutationFn: (dto: IAddRoleUsers) =>
      apiFetch<IDefaultResponse>(USER_ROUTES.addRoleToUsers(), { method: "POST", data: dto }),
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: ["users"] });
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.tree] });
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.treeNodes] });
      toast.success("Роль присвоена");
    }
  });

export const useUpdateRole = (keys?: unknown[]) =>
  useMutation({
    mutationKey: (keys || []).concat(["update-role"]),
    mutationFn: (role: IRole) =>
      apiFetch<IDefaultResponse<IRole>>(USER_ROUTES.updateRole(), { method: "PATCH", data: role }),
    onSuccess({ data }) {
      void queryClient.invalidateQueries({ queryKey: ["roles"] });
      toast.success(data.message);
    },
    onError({ message }) {
      toast.error(message);
    }
  });

export const useRemoveRole = (keys?: unknown[]) =>
  useMutation({
    mutationKey: (keys || []).concat(["remove-role"]),
    mutationFn: (roleId: string) => apiFetch<IDefaultResponse>(USER_ROUTES.removeRole(roleId), { method: "DELETE" }),
    onSuccess({ data }) {
      void queryClient.invalidateQueries({ queryKey: ["roles"] });
      toast.success(data.message);
    }
  });
