import { DefaultError, useMutation, useQuery } from "@tanstack/react-query";
import { GetNodeQueryParams, INode, IRoute, SKM_TREE_ROUTES } from "packages/shared/src/monitors";
import { toast } from "react-toastify";

import { IDefaultResponse } from "@skm-shared/api";

import { apiFetch } from "@shared/api/api-fetch";
import { queryClient } from "@shared/api/queryClient";

export const MONITOR_TREE_QUERY_KEYS = {
  tree: "tree",
  treeNodes: "tree-nodes",
  updateNode: "update-node",
  addNode: "add-node",
  removeNode: "remove-node"
};

export const useGetMonitorTree = () =>
  useQuery({
    queryKey: [MONITOR_TREE_QUERY_KEYS.tree],
    queryFn: () => apiFetch<IRoute[]>(SKM_TREE_ROUTES.getTree(), { method: "GET" }),
    staleTime: 60000,
    gcTime: 60000,
    select: ({ data }) => data
  });

export const useGetMonitorNodes = (params?: GetNodeQueryParams) =>
  useQuery({
    queryKey: [MONITOR_TREE_QUERY_KEYS.treeNodes, params?.title],
    queryFn: () => apiFetch<INode[]>(SKM_TREE_ROUTES.getNodes(), { method: "GET", params }),
    staleTime: 60000,
    gcTime: 60000,
    select: ({ data }) => ({
      list: data,
      hashMap: (by: "id" | "path") => new Map(data.map(node => [node[by], node]))
    })
  });

export const useUpdateNode = (keys?: unknown[]) =>
  useMutation({
    mutationKey: (keys || []).concat([MONITOR_TREE_QUERY_KEYS.updateNode]),
    mutationFn: async (node: INode) =>
      (await apiFetch<IDefaultResponse<IRoute>>(SKM_TREE_ROUTES.updateNode(node.id), { method: "PATCH", data: node }))
        .data,
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.tree] });
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.treeNodes] });
      toast.success("Маршрут обновлён");
    },
    onError(error: DefaultError) {
      toast.error(error.message);
    }
  });

export const useAddNode = () =>
  useMutation({
    mutationKey: [MONITOR_TREE_QUERY_KEYS.addNode],
    mutationFn: async (node: Omit<INode, "id">) =>
      (await apiFetch<IDefaultResponse<IRoute>>(SKM_TREE_ROUTES.addNode(), { method: "POST", data: node })).data,
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.tree] });
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.treeNodes] });
      toast.success("Маршрут добавлен");
    }
  });

export const useRemoveNode = (keys?: unknown[]) =>
  useMutation({
    mutationKey: (keys || []).concat([MONITOR_TREE_QUERY_KEYS.removeNode]),
    mutationFn: async (nodeId: string) =>
      (await apiFetch<IDefaultResponse<IRoute>>(SKM_TREE_ROUTES.removeNode(nodeId), { method: "DELETE" })).data,
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.tree] });
      void queryClient.invalidateQueries({ queryKey: [MONITOR_TREE_QUERY_KEYS.treeNodes] });
    }
  });
