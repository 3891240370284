import { FC } from "react";

import { Button, Combobox, Dialog, FormControlLabel, MultiCombobox } from "@brusnika.tech/ui-kit";
import NiceModal, { useModal } from "@ebay/nice-modal-react";
import { Stack } from "@mui/material";
import { Controller, useForm } from "react-hook-form";

import { useGetRoles } from "@entities/role/api/role.service";
import { useGetUsers } from "@entities/user/api/user.service";
import { isOptionEqualToValue } from "@shared/lib/utils";
import { ISelectElement } from "@shared/types";

import { AddRoleUsersFormType } from "../types";

interface Props {
  onSubmit: (form: AddRoleUsersFormType) => void;
}

const AssignRoleModal = NiceModal.create(({ onSubmit }: Props) => {
  const modal = useModal();

  const { data: roles = [] } = useGetRoles();
  const { data: users } = useGetUsers();

  const optionsRole = roles.map<ISelectElement>(r => ({ label: `${r.name} - ${r.level} уровень`, src: r.id }));
  const optionsUser = users?.list.map<ISelectElement>(r => ({ label: r.email, src: r.id })) ?? [];

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm<AddRoleUsersFormType>({
    values: { role: undefined, users: [] }
  });

  return (
    <Dialog open={modal.visible} title="Присвоить роль" onClose={modal.hide}>
      <Stack alignItems="end" gap={4}>
        <Stack direction="row" gap={4}>
          <Controller
            control={control}
            name="role"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <Combobox
                    {...field}
                    isOptionEqualToValue={(option, value) => option.src === value.src}
                    options={optionsRole}
                    sx={{ width: 200 }}
                    onChange={(_, value) => field.onChange(value)}
                  />
                }
                label="Роль"
                labelPlacement="top"
                sx={{ margin: 0 }}
              />
            )}
          />

          <Controller
            control={control}
            name="users"
            render={({ field }) => (
              <FormControlLabel
                control={
                  <MultiCombobox
                    {...field}
                    isOptionEqualToValue={isOptionEqualToValue}
                    noTags={false}
                    options={optionsUser}
                    state={errors.users ? "error" : undefined}
                    sx={{ minWidth: 200 }}
                    tagScrolling={true}
                    onChange={(_, value) => field.onChange(value)}
                  />
                }
                label="Пользователи"
                labelPlacement="top"
                sx={{ margin: 0 }}
              />
            )}
          />
        </Stack>

        <Button
          variant="filled"
          onClick={e => {
            void handleSubmit(onSubmit)(e);
            reset({ role: undefined, users: [] });
          }}
        >
          Присвоить
        </Button>
      </Stack>
    </Dialog>
  );
});

export default AssignRoleModal;
