import { useEffect, useMemo, useState } from "react";

import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";

import { IRoute } from "@skm-shared/monitors";

import { useGetCameraNodes, useGetCameraTree } from "@entities/camera/api/camera.service";
import { useCameraTreeStore } from "@entities/camera/model/camera-tree.store";
import { NestedTree } from "@shared/ui/tree/NestedTree";
import { createFolder, linksHandler } from "@widgets/sidebars/lib";
import { SidebarNames, TreeItem } from "@widgets/sidebars/types";
import SidebarItem from "@widgets/sidebars/ui/SidebarItem";

const CameraSidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const setActiveNode = useCameraTreeStore(state => state.setActiveNode);

  const { data: nodeData, isLoading } = useGetCameraNodes();
  const { data: tree = [] } = useGetCameraTree();

  const [selectedId, setSelectedId] = useState<string>("");

  const treeEntry = useMemo(() => {
    return createFolder({
      id: "root",
      text: "root",
      children: tree[0]?.children.map(item => linksHandler(item as IRoute, SidebarNames.MAIN)) ?? []
    });
  }, [tree]);

  const onSelectItem = (id: string) => {
    setSelectedId(id);
    setActiveNode(id);
  };

  const handleItemClick = (item: TreeItem) => {
    const node = nodeData?.mapById.get(item.id);
    if (!node) return;
    navigate(node.path);
    onSelectItem(item.id);
  };

  useEffect(() => {
    if (nodeData) {
      const selectedNode = nodeData?.mapById.get(location.pathname.split("/").at(-1) ?? "");

      setSelectedId(selectedNode?.id || "");
    }
  }, [location.pathname, isLoading]);

  return (
    <Stack>
      <NestedTree<TreeItem>
        isSelected={item => item.id === selectedId}
        item={treeEntry}
        itemContent={item => <SidebarItem item={item} sidebarName={SidebarNames.CAMERAS} />}
        name={SidebarNames.CAMERAS}
        paddingLeftMap={(_, theme, level, isDesktop) => theme.spacing((isDesktop ? 4 : 2) + 4 * (level - 1))}
        onItemClick={handleItemClick}
      />
    </Stack>
  );
};

export default CameraSidebar;
