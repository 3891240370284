import { Dispatch, SetStateAction, useCallback, useState } from "react";

import { BaseListItem } from "@shared/ui/tree/types";

export function getPathToNestedItem<T extends BaseListItem>(
  item: T,
  isSelected?: (item: T) => boolean
): string[] | undefined {
  const ids: string[] = [item.id];

  if (isSelected?.(item)) {
    return [];
  }

  if (item.children.length) {
    for (const child of item.children) {
      const found = getPathToNestedItem(child as T, isSelected);

      if (found) {
        ids.push(...found);
        return ids;
      }
    }
  }

  return undefined;
}

type SetValue<T> = Dispatch<SetStateAction<T>>;

export function useLocalStorageGeneric<T>(key: string, initialValue: T): [T, SetValue<T>] {
  const [storedValue, setStoredValue] = useState<T>((): T => {
    if (typeof window === "undefined") {
      return initialValue;
    }
    try {
      const item = window.localStorage.getItem(key);
      if (typeof initialValue === "string") {
        return (item ?? initialValue) as unknown as T;
      }
      return parseJSON<T>(item) ?? initialValue;
    } catch (error) {
      return initialValue;
    }
  });

  // Supports atomic updates
  const setValue: SetValue<T> = useCallback(
    (value: T | ((prevState: T) => T)) => {
      try {
        setStoredValue(prev => {
          // Determine the new value
          const newValue = typeof value === "function" ? (value as (prevState: T) => T)(prev) : value;
          // Save to localStorage
          if (typeof window !== "undefined") {
            window.localStorage.setItem(key, JSON.stringify(newValue));
          }

          // Return the new value for the useState
          return newValue;
        });
      } catch (error) {
        // handle the error, maybe log it
      }
    },
    [key]
  );

  return [storedValue, setValue];
}

function parseJSON<T>(value: string | null): T | undefined {
  try {
    return value === "undefined" ? undefined : (JSON.parse(value ?? "") as T);
  } catch {
    return undefined;
  }
}
