import { FC, useEffect } from "react";

import { Button } from "@brusnika.tech/ui-kit";
import { Stack, TextField } from "@mui/material";
import { useForm } from "react-hook-form";

import { ICreateCommentForm } from "@features/page-comment/types";

interface Props {
  onSubmit: (form: ICreateCommentForm) => void;
}

const PageCommentCreateForm: FC<Props> = ({ onSubmit }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful, errors }
  } = useForm<ICreateCommentForm>();

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset({ comment: "" });
    }
  }, [isSubmitSuccessful, reset]);

  return (
    <Stack direction="row" justifyContent="space-between">
      <TextField {...register("comment", { required: true })} error={!!errors.comment} placeholder="Комментарий" />

      <Button
        variant="filled"
        onClick={e => {
          void handleSubmit(onSubmit)(e);
          reset();
        }}
      >
        Добавить
      </Button>
    </Stack>
  );
};

export default PageCommentCreateForm;
