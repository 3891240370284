import { FC } from "react";

import { Button, Combobox, FormControlLabel } from "@brusnika.tech/ui-kit";
import { Stack, TextField } from "@mui/material";
import { alphabetical } from "radash";
import { Controller, useForm } from "react-hook-form";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { getSelectElement, isOptionEqualToValue } from "@shared/lib/utils";

import { ILastUpdateFormCreate } from "../types";

interface Props {
  onSubmit: (form: ILastUpdateFormCreate) => void;
}

const UpdateMonitorCreateForm: FC<Props> = ({ onSubmit }) => {
  const { data: nodeData } = useGetMonitorNodes();

  const options = alphabetical(
    nodeData?.list.map(node => getSelectElement(node.id, node.title)) ?? [],
    item => item.label
  );

  const { register, control, handleSubmit } = useForm<ILastUpdateFormCreate>();

  return (
    <Stack alignItems="end" direction="row">
      <FormControlLabel
        control={<TextField {...register("view")} sx={{ width: 200 }} />}
        label="Название"
        labelPlacement="top"
      />

      <FormControlLabel
        control={<TextField {...register("datetime")} sx={{ width: 150 }} />}
        label="Дата"
        labelPlacement="top"
      />

      <Controller
        control={control}
        name={"skmTreeNodeId"}
        render={({ field }) => (
          <FormControlLabel
            control={
              <Combobox
                {...field}
                isOptionEqualToValue={isOptionEqualToValue}
                options={options}
                sx={{ width: 250 }}
                onChange={(_, value) => field.onChange(value)}
              />
            }
            label="Страница"
            labelPlacement="top"
          />
        )}
      />

      <Button variant="filled" onClick={handleSubmit(onSubmit)}>
        Добавить
      </Button>
    </Stack>
  );
};

export default UpdateMonitorCreateForm;
