import { FC, useMemo, useState } from "react";

import { Grid, Typography } from "@mui/material";
import { alphabetical } from "radash";
import { toast } from "react-toastify";

import { ILastUpdate } from "@skm-shared/dashboard-last-update";

import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import {
  useRemoveLastUpdateMonitor,
  useUpdateLastUpdateMonitor
} from "@entities/update-monitor/api/update-monitor.service";
import { ILastUpdateFormUpdate } from "@features/update-monitor/types";
import { checkDate } from "@widgets/update-monitor/utils";

import UpdateMonitorItem from "./UpdateMonitorItem";
import UpdateMonitorUpdateForm from "./UpdateMonitorUpdateForm";

interface Props {
  items: ILastUpdate[];
}

const UpdateMonitorList: FC<Props> = ({ items }) => {
  const { mutateAsync: mutateUpdate } = useUpdateLastUpdateMonitor();
  const { mutate: mutateRemove } = useRemoveLastUpdateMonitor();
  const { data: nodeData } = useGetMonitorNodes();

  const [editingItem, setEditingItem] = useState<string | null>(null);
  const pageTitlePlaceholder = "Страница не привязана";

  const hashMapById = useMemo(() => nodeData?.hashMap("id"), [nodeData]);
  const tableData = alphabetical(
    items.map<ILastUpdate & { title: string }>(item => ({
      ...item,
      title: hashMapById?.get(item.skmTreeNodeId ?? "")?.title ?? pageTitlePlaceholder
    })),
    item => item.title
  );

  const onSetEdit = (id: string | null) => {
    setEditingItem(id);
  };

  const onEdit = async (form: ILastUpdateFormUpdate) => {
    try {
      const date = checkDate(form.datetime);

      if (!date) {
        toast.error("Введите дату в формате DD.MM.YYYY HH:mm");
        return;
      }

      await mutateUpdate({
        id: form.id,
        view: form.view,
        datetime: date,
        skmTreeNodeId: form.skmTreeNodeId?.src
      });

      onSetEdit(null);
    } catch (e) {
      toast.error("Ошибка обновления");
    }
  };

  const onRemove = (id: string) => {
    mutateRemove(id);
  };

  return (
    <Grid container alignItems="center" columnSpacing={4} justifyContent="flex-start" overflow="auto" rowSpacing={2}>
      <Grid item sm={4}>
        <Typography fontSize={14} fontWeight="bold">
          Название
        </Typography>
      </Grid>

      <Grid item sm={3}>
        <Typography fontSize={14} fontWeight="bold">
          Страница
        </Typography>
      </Grid>

      <Grid item sm={2}>
        <Typography fontSize={14} fontWeight="bold">
          Дата
        </Typography>
      </Grid>

      <Grid item sm={3}>
        <Typography fontSize={14} fontWeight="bold">
          Действия
        </Typography>
      </Grid>

      {tableData.map(item =>
        editingItem === item.id ? (
          <UpdateMonitorUpdateForm item={item} onSetEdit={onSetEdit} onSubmit={onEdit} />
        ) : (
          <UpdateMonitorItem item={item} key={item.id} onRemove={onRemove} onSetEdit={onSetEdit} />
        )
      )}
    </Grid>
  );
};

export default UpdateMonitorList;
