import { APIRoutes, IDefaultResponse } from "../api";

import { ILastUpdate } from "./dashboard-last-update";

export interface DashboardLastUpdateApi {
  getAll(...omitted: any[]): Promise<IDefaultResponse<ILastUpdate[]>>;
  add(...omitted: any[]): Promise<IDefaultResponse>;
  update(...omitted: any[]): Promise<IDefaultResponse>;
  remove(...omitted: any[]): Promise<IDefaultResponse>;
}

export const PREFIX = "dashboard/last-updates";

export const DASHBOARD_LAST_UPDATE_ROUTES: APIRoutes<DashboardLastUpdateApi> = {
  getAll: () => `${PREFIX}`,
  add: () => `${PREFIX}`,
  update: () => `${PREFIX}`,
  remove: (id: string) => `${PREFIX}/${id}`
};
