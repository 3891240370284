import { useMemo } from "react";

import { IconTrash } from "@brusnika.tech/ui-icons";
import { IconButton } from "@brusnika.tech/ui-kit";
import { Stack } from "@mui/material";

import { IRole } from "@skm-shared/user";

import { useGetRoles, useRemoveRole, useUpdateRole } from "@entities/role/api/role.service";
import { formatDate } from "@shared/lib/utils/formatDate";
import Table from "@shared/ui/table/Table";
import { IColumn } from "@shared/ui/table/types";

const RoleTable = () => {
  const { data: roles = [] } = useGetRoles();
  const { mutate: mutateUpdateRole } = useUpdateRole();
  const { mutate: mutateRemoveRole } = useRemoveRole();

  const onChange = (rows: Record<number, IRole | undefined>) => {
    for (const key in rows) {
      const row = rows[key];

      if (row) {
        mutateUpdateRole(row);
      }
    }
  };

  const columns = useMemo<IColumn<IRole>[]>(
    () => [
      {
        header: "Название",
        accessor: "name",
        isEditable: true
      },
      {
        header: "Уровень",
        accessor: "level",
        isEditable: true
      },
      {
        header: "Дата создания",
        accessor: "createdAt",
        format: ({ value }) => formatDate(value, "DD.MM.YYYY HH:mm")
      },
      {
        header: "Действия",
        accessor: "actions",
        format: ({ row }) => (
          <IconButton onClick={() => mutateRemoveRole(row.id)}>
            <IconTrash />
          </IconButton>
        )
      }
    ],
    []
  );

  return (
    <Stack>
      <Table<IRole> columns={columns} data={roles} onChange={onChange} />
    </Stack>
  );
};

export default RoleTable;
