import { keepPreviousData, useMutation, useQuery } from "@tanstack/react-query";

import { IDefaultResponse } from "@skm-shared/api";
import { IStatisticVisit, IStatisticVisitCreate, STATISTIC_VISIT_ROUTES } from "@skm-shared/statistic-visit";

import { apiFetch } from "@shared/api/api-fetch";
import { queryClient } from "@shared/api/queryClient";

export const useGetVisits = (params: Record<string, any>, queryKey: any[] = []) =>
  useQuery({
    queryKey: ["user-visits"].concat(queryKey),
    queryFn: () =>
      apiFetch<IDefaultResponse<{ statistics: IStatisticVisit[]; all: number }>>(STATISTIC_VISIT_ROUTES.all(), {
        method: "GET",
        params
      }),
    placeholderData: keepPreviousData,
    select: ({ data }) => ({ visits: data.data.statistics, all: data.data.all })
  });

export const useAddVisit = () =>
  useMutation({
    mutationKey: ["add-user-visit"],
    mutationFn: (dto: IStatisticVisitCreate) =>
      apiFetch<IDefaultResponse<IStatisticVisit>>(STATISTIC_VISIT_ROUTES.create(), { data: dto, method: "POST" }),
    onSuccess() {
      void queryClient.invalidateQueries({ queryKey: ["user-visits"] });
    }
  });
