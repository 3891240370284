import { useEffect } from "react";

import { Spinner } from "@brusnika.tech/ui-kit";
import { useNavigate } from "react-router-dom";

import { useGetCameraTree } from "@entities/camera/api/camera.service";
import { useCameraTreeStore } from "@entities/camera/model/camera-tree.store";

const CameraPage = () => {
  const { data: tree = [] } = useGetCameraTree();

  const setActiveNode = useCameraTreeStore(state => state.setActiveNode);

  const navigate = useNavigate();
  const startRoute = tree[0];

  useEffect(() => {
    if (startRoute) {
      const startNode = startRoute.children[0]?.children[0]?.children[0];
      navigate(startNode.path, { replace: true });
      setActiveNode(startNode.id);
    }
  }, [startRoute, navigate]);

  return <Spinner text="Страница камер" />;
};

export default CameraPage;
