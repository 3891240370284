import { FC } from "react";

import {
  IconCollapse,
  IconCommentStroked,
  IconDocExport,
  IconDocOutline,
  IconExpand,
  IconSettings
} from "@brusnika.tech/ui-icons";
import { IconButton } from "@brusnika.tech/ui-kit";
import { HeaderActions as LayoutHeaderActions, useLayout } from "@brusnika.tech/ui-portal";
import { Stack } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { useRootStore } from "@app/root-store/root.store";
import { useGetMonitorNodes } from "@entities/monitor/api/tree.service";
import { useMonitorTreeStore } from "@entities/monitor/model/monitor-tree.store";
import { QueryParams, TopRoutes } from "@shared/consts";

interface Props {
  nodeId: string;
}

const MonitorHeaderActions: FC<Props> = ({ nodeId }) => {
  const location = useLocation();
  const { data: nodeData } = useGetMonitorNodes();
  const node = nodeData?.hashMap("id").get(nodeId);

  const navigate = useNavigate();
  const { toggleRightDrawer, isDesktop } = useLayout();

  const rootStore = useRootStore(useShallow(state => state));
  const isAdmin = useMonitorTreeStore(state => state.isAdmin);

  const onFullscreen = () => {
    rootStore.setFullscreen(!rootStore.isFullscreen);
  };

  const toSettings = () => {
    navigate(`/${TopRoutes.ADMIN_MONITOR_TREE}?${QueryParams.nodeId}=${nodeId}`);
  };

  const onDownloadPdf = (): void => {
    const a: HTMLAnchorElement = document.createElement("a");
    a.href = `${import.meta.env.VITE_BACKEND_URL}/external/monitor-pdf?path=${location.pathname}`;
    a.target = "_self";
    document.body.appendChild(a);
    a.click();
    a.remove();
  };

  return (
    <LayoutHeaderActions>
      <Stack alignItems={isDesktop ? "center" : "flex-end"} direction={isDesktop ? "row" : "column"} gap={1.5}>
        <IconButton onClick={onDownloadPdf}>
          <IconDocExport />
        </IconButton>

        {isAdmin && (
          <IconButton onClick={toSettings}>
            <IconSettings />
          </IconButton>
        )}

        <IconButton disabled={!node?.responsibleLink}>
          <a href={node?.responsibleLink} rel="noreferrer" target="_blank">
            <IconDocOutline iconSize="medium" />
          </a>
        </IconButton>

        <IconButton onClick={toggleRightDrawer}>
          <IconCommentStroked iconSize="medium" />
        </IconButton>

        <IconButton onClick={onFullscreen}>{rootStore.isFullscreen ? <IconCollapse /> : <IconExpand />}</IconButton>
      </Stack>
    </LayoutHeaderActions>
  );
};

export default MonitorHeaderActions;
