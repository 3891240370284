import { AppBar, defaultProjectServicesOptions, ITab, Layout, UserProfileBar } from "@brusnika.tech/ui-portal";
import cn from "classnames";
import { Outlet, useSearchParams } from "react-router-dom";
import { useShallow } from "zustand/react/shallow";

import { IUser } from "@skm-shared/user";

import { useRootStore } from "@app/root-store/root.store";
import JiraIssue from "@features/jira/ui/JiraIssue";
import { QueryParams } from "@shared/consts";
import SidebarSearch from "@widgets/sidebars/ui/SidebarSearch";

interface Props {
  navigationTree: ITab;
  user?: IUser;
}

const Root = ({ navigationTree, user }: Props) => {
  const [searchParams] = useSearchParams();
  const rootStore = useRootStore(useShallow(state => state));

  if (searchParams.get(QueryParams.onlyFrame)) {
    return <Outlet />;
  }

  return (
    <>
      <JiraIssue user={user ?? ({} as IUser)} />

      <Layout
        appBar={
          <AppBar
            className={cn({
              "g-fullscreen-header_hidden": rootStore.isFullscreen,
              "g-fullscreen-header": !rootStore.isFullscreen
            })}
            logo="B.ERP"
            name="СКМ"
            navigationTree={navigationTree}
            projectSelectOptions={defaultProjectServicesOptions}
            userAppBar={<SidebarSearch />}
            userProfile={<UserProfileBar isCollapsed avatarAlt={user?.name} topText={user?.email} />}
          />
        }
      >
        <Outlet />
      </Layout>
    </>
  );
};

export default Root;
