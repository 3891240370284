import { FC } from "react";

import { Checkbox, FormControlLabel, CheckboxProps } from "@brusnika.tech/ui-kit";

interface Props extends CheckboxProps {
  label: string;
  labelPlacement?: "end" | "start" | "top" | "bottom" | undefined;
}

const CheckboxWithLabel: FC<Props> = ({ label, labelPlacement, ...props }) => {
  return <FormControlLabel control={<Checkbox {...props} />} label={label} labelPlacement={labelPlacement} />;
};

export default CheckboxWithLabel;
