import { useQuery } from "@tanstack/react-query";

import { IDefaultResponse } from "@skm-shared/api";
import { AppConfig } from "@skm-shared/config";
import { CONFIG_ROUTES } from "@skm-shared/config/config.api";

import { apiFetch } from "@shared/api/api-fetch";

export const useGetConfig = () =>
  useQuery({
    queryKey: ["config"],
    queryFn: () => apiFetch<IDefaultResponse<AppConfig>>(CONFIG_ROUTES.getConfig(), { method: "GET" }),
    select: ({ data }) => data.data
  });
