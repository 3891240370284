import { PaddedContentContainer } from "@brusnika.tech/ui-portal";

import UserTable from "@features/user/ui/UserTable";

const UsersPage = () => {
  return (
    <PaddedContentContainer style={{ backgroundColor: "#fff" }}>
      <UserTable />
    </PaddedContentContainer>
  );
};

export default UsersPage;
